function removerMascaraCelular(celular) {
    if (!celular) {
      return 0;
    }

    return celular
      .toString()
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "")
      .trim();
}

export default {
    removerMascaraCelular
}
