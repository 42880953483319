import api from '../api'
function obterTodos() {
  return new Promise((resolve, reject) => {
    return api.get('/pacientes')
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function obterPorId(id) {
  return new Promise((resolve, reject) => {
    return api.get(`/pacientes/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function cadastrar(paciente) {
  paciente = paciente || {};
  return new Promise((resolve, reject) => {
    return api.post('/pacientes', paciente)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}
function deletar(paciente) {
  paciente = paciente || {};
  return new Promise((resolve, reject) => {
    return api.delete(`/pacientes/${paciente.id}`, paciente)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}
function atualizar(paciente) {
  paciente = paciente || {};
  return new Promise((resolve, reject) => {
    return api.put(`/pacientes/${paciente.id}`, paciente)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}


export default {
  obterTodos,
  obterPorId,
  cadastrar,
  deletar,
  atualizar,
}