class PacienteModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    /*informações básicas*/
    this.guidAssinante = obj.guidAssinante;
    this.nome = obj.nome;
    this.codigoNome = `${obj.id} - ${obj.nome}`;
    this.email = obj.email && obj.email.toLowerCase();
    this.sexo = obj.sexo && obj.sexo.toUpperCase();
    this.dataNascimento = obj.dataNascimento;
    this.celular = obj.celular;
    this.idUsuario = parseInt(obj.idUsuario);

    /*informações adicionais*/
    this.classificacao = obj.classificacao || [];
    this.foto = obj.foto;
    this.rg = obj.rg;
    this.cpf = obj.cpf;
    this.profissao = obj.profissao;
    this.nomeMae = obj.nomeMae;
    this.nomePai = obj.nomePai;
    this.convenio = obj.convenio;
    this.numeroCarteira = obj.numeroCarteira;
    this.cep = obj.cep;
    this.endereco = obj.endereco;
    this.numero = obj.numero;
    this.complemento = obj.complemento;
    this.bairro = obj.bairro;
    this.estado = obj.estado;
    this.cidade = obj.cidade;
    // this.tipoContato = new TipoContatoDTO(obj.tipoContato);
    this.contato = obj.contato;
    this.idPacienteMae = obj.idPacienteMae;
    this.idPacientePai = obj.idPacientePai;

    /*informações privadas*/
    this.alergias = obj.alergias || [];
    this.vegano = obj.vegano;
    this.observacao = obj.observacao;
    this.dataInativacao = obj.dataInativacao;
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  modeloValido() {
    return !!(this.nome && this.email && this.senha && this.perfil);
  }
}
module.exports = PacienteModel