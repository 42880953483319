<template>
  <div class="ma-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="5000"
      top="top"
      :color="tipo"
      elevation="0"
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12" lg="12">
        <h1>Pacientes</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <!-- Skeleton Loader -->
    <v-row v-show="loading">
      <v-col cols="12" lg="12">
        <v-skeleton-loader type="button" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- Vazia -->
    <v-row v-show="!this.pacientes.length && !loading">
      <TelaVazia
        :imagemSrc="'pacientes.svg'"
        :titulo="'Não há pacientes cadastrados'"
        :subtitulo="'Adicione um paciente para iniciar um atendimento'"
        :textoBotaoPrimario="'Cadastrar Paciente'"
        :eventoBotaoPrimario="adicionarPaciente"
      >
      </TelaVazia>
    </v-row>

    <!-- Pacientes Cadastrados -->
    <v-row v-show="this.pacientes.length">
      <v-col cols="12" lg="8">
        <v-btn @click="adicionarPaciente()" text class="btn-primario"
          >Novo paciente</v-btn
        >
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="12">
        <v-card class="card-cuitatto">
          <v-data-table
            :headers="colunas"
            :items="pacientes"
            :items-per-page="10"
            :search="search"
            class="elevation-0"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-left" @click="visualizarPaciente(item)">
                    <span :inner-html.prop="item.id"></span>
                  </td>
                  <td class="text-left" @click="visualizarPaciente(item)">
                    <span
                      :inner-html.prop="item.nome | marcarTexto(search)"
                    ></span>
                  </td>
                  <td class="text-center" @click="visualizarPaciente(item)">
                    {{ item.criadoEm | data }}
                  </td>
                  <td class="text-left" @click="visualizarPaciente(item)">
                    <span
                      :inner-html.prop="
                        item.classificacao.toString().replaceAll(',', ', ')
                          | marcarTexto(search)
                      "
                    ></span>
                  </td>
                  <td class="text-left" @click="visualizarPaciente(item)">
                    {{
                      item.sexo
                        .replace("M", "Masculino")
                        .replace("F", "Feminino")
                    }}
                  </td>
                  <td class="text-center" @click="visualizarPaciente(item)">
                    {{ item.dataNascimento | idade }}
                  </td>
                  <td class="coluna-botoes text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          @click="visualizarPaciente(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <eye-icon size="1.3x" class="custom-class"></eye-icon>
                        </v-btn>
                      </template>
                      <span>Visualizar</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          @click="editarPaciente(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <edit-2-icon
                            size="1.3x"
                            class="custom-class"
                          ></edit-2-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          @click="excluirPaciente(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <trash-icon size="1.3x" class="custom-class">
                          </trash-icon>
                        </v-btn>
                      </template>
                      <span>Excluir</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <janela-confirmacao
      v-model="abrirJanelaConfirmacao"
      :callbackPrimario="callbackConfirmarExclusao"
      :mensagem="mensagemDeExclusao"
    />
    <!-- Modal de cadastro de paciente -->
    <v-dialog
      persistent
      scrollable
      min-height="800px"
      max-width="800px"
      v-model="janelaCadastro"
    >
      <v-card flat>
        <v-card-title>
          <h1 class="titulo-modal">
            {{ !modoEdicao ? "Adicionar " : "Editar " }} paciente
          </h1>
        </v-card-title>

        <v-card-text>
          <v-divider></v-divider>
          <div class="py-4"></div>
          <v-card flat class="pa-5">
            <!-- Informações Basicas -->
            <div class="cabecalho-cadastro">
              <h2>
                <user-icon size="1x" class="mr-2"></user-icon> Informações
                básicas
              </h2>
            </div>
            <div class="conteudo-informacoes">
              <v-row>
                <v-col cols="12" lg="">
                  <v-row class="py-10">
                    <v-col cols="12" lg="8">
                      <v-text-field
                        label="Nome do paciente"
                        dense
                        :autofocus="true"
                        v-model="paciente.nome"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        label="E-mail"
                        dense
                        v-model="paciente.email"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="5">
                      <v-radio-group row class="py-0" v-model="paciente.sexo">
                        <v-radio value="M" :label="'Masculino'"></v-radio>
                        <v-radio value="F" :label="'Feminino'"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <date-picker
                        label="Data de nascimento"
                        v-model="paciente.dataNascimento"
                        data-minima="1900-01-01"
                        dense
                      >
                      </date-picker>
                    </v-col>
                    <v-col cols="12" lg="3">
                      <v-text-field
                        label="Celular"
                        dense
                        v-model="paciente.celular"
                        v-mask="'(##) #####-####'"
                        autocomplete="off"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                      <v-file-input
                        label="Selecione a foto do seu paciente"
                        dense
                        prepend-icon="mdi-camera"
                        v-model="paciente.foto"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>

            <!-- Informações Adicionais -->
            <div class="cabecalho-cadastro">
              <h2>
                <heart-icon size="1x" class="mr-2"></heart-icon> Informações
                adicionais
              </h2>
            </div>

            <div class="conteudo-informacoes">
              <v-row class="py-10 pb-4">
                <v-col cols="12" lg="12">
                  <v-autocomplete
                    label="Classificação"
                    :items="listaTiposDeClassificacao"
                    v-model="paciente.classificacao"
                    clearable
                    return-object
                    multiple
                    dense
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    label="CPF"
                    dense
                    v-model="paciente.cpf"
                    v-mask="'###.###.###-##'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    label="RG"
                    v-model="paciente.rg"
                    dense
                    v-mask="'##.###.###-#'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    label="Profissão"
                    v-model="paciente.profissao"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-checkbox
                    v-model="possuiPlanoDeSaude"
                    dense
                    :label="'Possui Plano de Saúde'"
                  >
                  </v-checkbox>
                </v-col>
                <v-col v-show="possuiPlanoDeSaude" cols="12" lg="4">
                  <v-autocomplete
                    label="Convenio"
                    :items="listaTiposDeConvenio"
                    v-model="paciente.convenio"
                    return-object
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col v-show="possuiPlanoDeSaude" cols="12" lg="4">
                  <v-text-field
                    label="Carteira"
                    v-model="paciente.numeroCarteira"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" lg="6">
                  <v-text-field
                    label="Nome do Pai"
                    v-model="paciente.nomePai"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    label="Nome da Mãe"
                    v-model="paciente.nomeMae"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-text-field
                    label="Cep"
                    v-model="paciente.cep"
                    @blur="obterEnderecoPorPeloCEP"
                    v-mask="'#####-###'"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="9">
                  <v-text-field
                    label="Endereço"
                    v-model="paciente.endereco"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="2">
                  <v-text-field label="Número" v-model="paciente.numero" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field label="Bairro" v-model="paciente.bairro" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    label="Complemento"
                    v-model="paciente.complemento"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="2">
                  <v-autocomplete
                    label="Estado"
                    :items="listaDeEstados"
                    hide-selected
                    v-model="paciente.estado"
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="5">
                  <v-autocomplete
                    :items="listaDeCidades"
                    label="Cidade"
                    v-model="paciente.cidade"
                    no-data-text="Selecine uma UF para listar as cidades"
                    autocomplete="off"
                    clearable
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12" lg="4">
                  <v-autocomplete
                    label="Tipo de Contato"
                    :items="listaTiposDeContato"
                    v-model="paciente.tipoDeContato"
                    return-object
                    clearable
                    dense
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="8">
                  <v-text-field
                    label="Contato"
                    dense
                    v-model="paciente.contato"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <!-- Informações Adicionais -->
            <div class="cabecalho-cadastro mt-10">
              <h2>
                <heart-icon size="1x" class="mr-2"></heart-icon> Informações
                adicionais
              </h2>
            </div>

            <div class="conteudo-informacoes">
              <v-row class="py-10 pb-4">
                <v-col cols="12" lg="12">
                  <v-autocomplete
                    :items="listaDeAlergias"
                    label="Alergias"
                    v-model="paciente.alergias"
                    autocomplete="off"
                    clearable
                    multiple
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-switch
                    v-model="paciente.vegano"
                    :label="`Paciente é vegano`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" lg="12">
                  <v-textarea
                    label="Observação"
                    dense
                    v-model="paciente.observacao"
                    no-resize
                    outlined
                    rows="3"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn text class="btn-primario mr-2" @click="salvar()">
              Salvar
            </v-btn>
            <v-btn text class="btn-secundario" @click="fechar()">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Loading Salvamento -->
    <v-dialog persistent max-width="400px" v-model="loadingCadastro">
      <v-card
        min-height="400px"
        max-width="400px"
        flat
        class="alinhar-centralizar"
      >
        <v-progress-circular
          :size="150"
          :width="5"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h2 class="loading-titulo">Carregando</h2>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CONVENIO, ALERGIAS } from "@/constants/geral-constantes";

// Components
import DatePicker from "@/components/comum/Datepicker"; //Deixei comentado, pois o componente está no branch NUTRI-33
import JanelaConfirmacao from "@/components/comum/JanelaConfirmacao";
import TelaVazia from "@/components/comum/TelaVazia";

// Apis
import apiViaCEP from "@/api/geral/cep-api";
import apiIBGE from "@/api/geral/ibge-api";
import apiTiposDeContato from "@/api/tipo-de-contato-api";
import apiPacientes from "@/api/paciente/paciente-api";
import apiClassificacoes from "@/api/agendamento/classificacoes-api";
import moment from "moment";

// Models
import PacienteModel from "@/model/paciente/paciente-model";

// Mixin
import MixinMensagem from "@/mixin/mixin-mensagem";
import MixinAutorizacao from "@/mixin/mixin-autorizacao";

// Utils
import utilsData from "@/utils/data.js";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "ControleDePaciente",
  mixins: [MixinMensagem, MixinAutorizacao],
  components: { DatePicker, JanelaConfirmacao, TelaVazia },

  data() {
    return {
      search: "",
      valid: true,
      modoEdicao: false,
      janelaCadastro: false,
      abrirJanelaConfirmacao: false,
      mensagemDeExclusao: "",
      callbackConfirmarExclusao: () => {},
      loading: true,
      listaDeAlergias: ALERGIAS,
      listaTiposDeClassificacao: [],
      listaTiposDeConvenio: CONVENIO,
      listaTiposDeContato: [],
      listaDeCidades: [],
      listaDeUFs: [],
      listaDeEstados: [],
      possuiPlanoDeSaude: false,
      colunas: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
        },
        {
          text: "Cadastro",
          align: "center",
          value: "criadoEm",
          sortable: true,
        },
        { text: "Classificação", value: "classificacao", sortable: true },
        { text: "Sexo", value: "sexo", sortable: true },
        {
          text: "Idade",
          align: "center",
          value: "dataNascimento",
          sortable: true,
        },
        {
          text: "",
          align: "right",
          value: "opcoes",
          sortable: true,
          width: "140px",
        },
      ],
      paciente: new PacienteModel(),
      pacientes: [],
      campoObrigatorio: [(v) => !!v || "Este campo é obrigatório"],
      loadingCadastro: false,
    };
  },

  watch: {
    "paciente.estado": function (uf) {
      this.obterObterCidadesPorUF(uf);
    },
  },

  mounted() {
    mixpanel.track("page_view", { nome_pagina: "controle_pacientes" });
    this.limparCampos();
    this.obterUFs();
    this.obterTiposDeContato();
    this.obterTodosPacientes();
    this.obterTodasClassificacoes();
    this.loading = true;
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
    marcarTexto: function (valor, destaque) {
      if (destaque == "") {
        return valor;
      }
      return valor.replace(
        new RegExp(destaque, "ig"),
        (v) =>
          `<span style="background-color: rgba(194, 152, 121, 0.3);">${v}</span>`
      );
    },
    idade(valor) {
      return utilsData.calcularIdadeAnoMesesDias(valor);
    },
  },

  methods: {
    adicionarPaciente() {
      mixpanel.track("click", {
        nome_pagina: "controle_pacientes",
        nome_botao: "btn_novo_paciente",
      });
      this.limparCampos();
      this.campoObrigatorio = false;
      this.janelaCadastro = true;
      this.modoEdicao = false;
    },

    editarPaciente(paciente) {
      mixpanel.track("click", {
        nome_pagina: "controle_pacientes",
        nome_botao: "btn_icone_editar_paciente",
      });
      this.paciente = new PacienteModel(paciente);
      this.modoEdicao = true;
      this.janelaCadastro = true;
    },

    excluirPaciente(paciente) {
      mixpanel.track("click", {
        nome_pagina: "controle_pacientes",
        nome_botao: "btn_icone_excluir_paciente",
      });
      this.paciente = new PacienteModel(paciente);
      this.abrirJanelaConfirmacao = true;
      this.callbackConfirmarExclusao = this.deletarPaciente;
      this.mensagemDeExclusao = `Deseja excluir o paciente <b>${this.paciente.nome}</b>? <br> <br> Todos os dados médicos deste paciente <b>serão permanentemente excluidos.</b>`;
    },

    obterTodosPacientes() {
      apiPacientes
        .obterTodos()
        .then((resposta) => {
          this.pacientes = resposta.data.map((p) => new PacienteModel(p));
          this.loading = false;
        })
        .catch((erro) => {
          this.dispararMensagem(
            (erro.response && erro.response.data.mensagem) || erro,
            "error"
          );
          this.loading = false;
        });
    },

    obterTodasClassificacoes() {
      apiClassificacoes
        .obterTodos()
        .then((resposta) => {
          this.listaTiposDeClassificacao = resposta.data.map(
            (c) => c.descricao.charAt(0).toUpperCase() + c.descricao.slice(1)
          );
        })
        .catch(/* Aqui avisa que deu ruim */);
    },

    obterEnderecoPorPeloCEP() {
      let cep = this.paciente.cep
        .toString()
        .replaceAll(".", "")
        .replaceAll("-", "");

      apiViaCEP
        .obterEnderecoPorCep(cep)
        .then((resposta) => {
          if (resposta.data.erro === undefined) {
            this.obterObterCidadesPorUF(resposta.data.uf);
            this.paciente.endereco = resposta.data.logradouro;
            this.paciente.cidade = resposta.data.localidade;
            this.paciente.bairro = resposta.data.bairro;
            this.paciente.complemento = resposta.data.complemento;
            this.paciente.estado = resposta.data.uf;
          } else {
            this.dispararMensagem(
              "Este cep não existe. Preencha manualmente o endereço",
              "error"
            );
          }
        })
        .catch();
    },

    obterObterCidadesPorUF(uf) {
      apiIBGE.obterMunicipios(uf).then((resposta) => {
        this.listaDeCidades = resposta.data.map((c) => c.nome);
      });
    },

    obterTiposDeContato() {
      apiTiposDeContato
        .obterTodos()
        .then((resposta) => {
          this.listaTiposDeContato = resposta.data.map(
            (t) => t.descricao.charAt(0).toUpperCase() + t.descricao.slice(1)
          );
        })
        .catch(/* Aqui avisa que deu ruim */);
    },
    obterUFs() {
      apiIBGE.obterEstados().then((resposta) => {
        this.listaDeEstados = resposta.data.map((uf) => uf.sigla);
      });
    },
    converterImagemParaBase64(file) {
      if (!file) {
        return new Promise((resolve) => {
          resolve("");
        });
      }
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    cadastrarNovoPaciente() {
      this.tirarMascara();

      this.converterImagemParaBase64(this.paciente.foto)
        .then((data) => {
          this.paciente.foto = data;
          this.paciente.idUsuario = parseInt(this.usuario.id);
          this.loadingCadastro = true;
          apiPacientes
            .cadastrar(this.paciente)
            .then((resposta) => {
              this.pacientes.push(new PacienteModel(resposta.data));
              this.limparCampos();
              this.janelaCadastro = false;
              this.dispararMensagem(
                "Paciente cadastrado com sucesso!",
                "success"
              );
            })
            .catch((erro) => {
              this.dispararMensagem(
                (erro.response && erro.response.data.mensagem) || erro,
                "error"
              );
            })
            .finally(() => {
              this.loadingCadastro = false;
            });
        })
        .catch(() => {
          this.dispararMensagem("Falha ao anexar a foto do paciente", "error");
        });
    },

    visualizarPaciente(paciente) {
      mixpanel.track("click", {
        nome_pagina: "controle_pacientes",
        nome_botao: "btn_icone_visualizar_paciente",
      });

      this.$router.push({
        name: "prontuario-paciente",
        params: { id: paciente.id },
      });
    },

    deletarPaciente() {
      apiPacientes
        .deletar(this.paciente)
        .then(() => {
          this.dispararMensagem(
            `Paciente ${this.paciente.nome} foi excluido com sucesso!`,
            "success"
          );
          this.atualizarPacienteNaLista(this.paciente, true);
        })
        .catch((erro) =>
          this.dispararMensagem("Falha na exclusão do paciente", erro, "error")
        )
        .finally((this.abrirJanelaConfirmacao = false));
    },

    atualizarPaciente() {
      this.modoEdicao = true;
      this.tirarMascara();

      if (this.paciente.foto && typeof this.paciente.foto != "string") {
        this.converterImagemParaBase64(this.paciente.foto)
          .then((data) => {
            this.paciente.foto = data;
            this.loadingCadastro = true;
            apiPacientes
              .atualizar(this.paciente)
              .then((resposta) => {
                this.dispararMensagem(
                  `${this.paciente.nome} foi atualizado(a) com sucesso!`,
                  "success"
                );
                this.janelaCadastro = false;
                this.atualizarPacienteNaLista(new PacienteModel(resposta.data));
              })
              .catch((erro) => {
                this.dispararMensagem(
                  (erro.response && erro.response.data.mensagem) || erro,
                  "error"
                );
              })
              .finally(() => {
                this.loadingCadastro = false;
              });
          })
          .catch(() => {
            this.dispararMensagem(
              "Falha ao anexar a foto do paciente",
              "error"
            );
          });
      } else {
        this.loadingCadastro = true;
        apiPacientes
          .atualizar(this.paciente)
          .then((resposta) => {
            this.dispararMensagem(
              `${this.paciente.nome} foi atualizado(a) com sucesso!`,
              "success"
            );
            this.janelaCadastro = false;
            this.atualizarPacienteNaLista(new PacienteModel(resposta.data));
          })
          .catch((erro) => {
            this.dispararMensagem(
              (erro.response && erro.response.data.mensagem) || erro,
              "error"
            );
          })
          .finally(() => {
            this.loadingCadastro = false;
          });
      }
    },

    atualizarPacienteNaLista(paciente, deletarDaLista) {
      var index = this.pacientes
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(paciente.id));
      !deletarDaLista
        ? this.pacientes.splice(index, 1, paciente) // Atualiza
        : this.pacientes.splice(index, 1); // Deleta
    },

    salvar() {
      !this.modoEdicao
        ? this.cadastrarNovoPaciente()
        : this.atualizarPaciente();
    },

    fechar() {
      this.limparCampos();
      this.janelaCadastro = false;
    },

    cancelar() {
      this.$router.go(-1);
    },

    limparCampos() {
      this.paciente = new PacienteModel();
    },

    tirarMascara() {
      this.paciente.celular =
        this.paciente.celular &&
        this.paciente.celular
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", "");
      this.paciente.cep =
        this.paciente.cep && this.paciente.cep.replace("-", "");
      this.paciente.cpf =
        this.paciente.cpf &&
        this.paciente.cpf.replaceAll(".", "").replace("-", "");
      this.paciente.rg =
        this.paciente.rg &&
        this.paciente.rg.replaceAll(".", "").replace("-", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.cabecalho-cadastro {
  color: $cor_primaria;
  padding-bottom: 5px;
  width: 100%;
  border-bottom: 1px solid rgba(102, 102, 102, 0.253);
}
.div-upload-foto {
  padding: 20px;
  // margin:10px;
  display: block;
  border-radius: 8px;
  width: 100%;
  height: 100%;

  .foto-paciente {
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    vertical-align: middle;
    background-image: src("./assets/img/paciente-placeholder.jpg");
  }
}

.alinhar-centralizar {
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
