import CalculoEnergeticoModel from '../consulta/calculo-energetico-model';
import PrescricaoDieteticaRefeicaoModel from './prescricao-dietetica-refeicao-model';

export default class PrescricaoDieteticaModel {
  constructor(obj) {
    obj = obj || {};
    let refeicoes = obj.refeicoes?.map(refeicao => new PrescricaoDieteticaRefeicaoModel(refeicao));

    this.id = obj.id;
    this.idPaciente = obj.idPaciente;
    this.idConsulta = obj.idConsulta;
    this.idUsuario = obj.idUsuario;
    this.dataInicial = obj.dataInicial;
    this.dataFinal = obj.dataFinal;
    this.descricao = obj.descricao;
    this.observacao = obj.observacao;
    this.getReferencia = new CalculoEnergeticoModel(obj.getReferencia);
    this.refeicoes = refeicoes?.length ? refeicoes : [new PrescricaoDieteticaRefeicaoModel()];
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  modeloValido() {
    
  }
}
