<template>
  <v-card flat>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="5000"
      top="top"
      :color="tipo"
      elevation="0"
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-row v-if="!this.gestacoes.length">
      <TelaVazia
        :imagemSrc="'cegonha.svg'"
        :titulo="'Não há gestações para este paciente'"
        :subtitulo="'Adicione um paciente para iniciar um atendimento'"
        :textoBotaoPrimario="'Adicionar antropometrias'"
        :exibirBotaoPrimario="true"
        :eventoBotaoPrimario="adicionarGestacao"
      >
      </TelaVazia>
    </v-row>

    <v-card-text v-show="this.gestacoes.length">
      <v-row class="text-left">
        <v-col cols="12" lg="9">
          <v-btn
            v-show="this.gestacaoSelecionada"
            class="btn-primario mr-2"
            text
            @click="adicionarAntropometria"
            ><plus-icon size="1x" class="mr-2"></plus-icon> Antropometria</v-btn
          >
          <v-btn class="btn-secundario" text @click="adicionarGestacao"
            ><plus-icon size="1x" class="mr-2"></plus-icon> Gestação</v-btn
          >
        </v-col>
        <v-col cols="12" lg="3">
          <v-autocomplete
            dense
            clearable
            auto-select-first
            :items="gestacoes"
            label="Selecione uma gestação"
            item-text="nome"
            item-value="id"
            v-model="gestacaoSelecionada"
            @change="obterAntropometrias(gestacaoSelecionada)"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- Antropometrias -->
      <div class="sem-registro" v-show="!this.antropometrias.length">
        <h1 class="titulo-sem-registro">Não há avaliações antropométricas</h1>
        <p class="subtitulo-sem-registro">
          Selecione uma gestação existente ou crie uma nova gestação
        </p>
      </div>
      <v-row v-show="this.antropometrias.length">
        <v-col cols="12" lg="7">
          <v-data-table
            dense
            :items="dadosTabelaAntropometria"
            :headers="colunasAntropometrias"
            :items-per-page="10"
            :sort-by.sync="ordenacaoTabelaAntropometria"
            :search="search"
            class="elevation-0"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-left">
                    {{ item.semanaGestacional }}
                  </td>
                  <td class="text-center">{{ item.peso }} Kg</td>
                  <td class="text-center">
                    {{ item.altura }}
                  </td>
                  <td class="text-center">
                    {{ item.imc }}
                  </td>
                  <td class="text-right">
                    {{ item.criadoEm | data }}
                  </td>
                  <td class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          @click="editarAntropometria(item)"
                          v-bind="attrs"
                          v-on="on"
                          v-if="!item.semanaGestacional == '0'"
                        >
                          <edit-2-icon size="1.1x"></edit-2-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="excluirAntropometria(item)"
                          v-if="!item.semanaGestacional == '0'"
                        >
                          <trash-icon size="1.1x"></trash-icon>
                        </v-btn>
                      </template>
                      <span>Excluir</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="5">
          <AntropometriaChart
            titulo="Antropometria de Gestante"
            :eixo-x="dadosAntropometriaGestante.SEMANASGESTACAO"
            :baixo-peso="dadosAntropometriaGestante.BAIXOPESO"
            :adequado="dadosAntropometriaGestante.ADEQUADO"
            :sobrepeso="dadosAntropometriaGestante.SOBREPESO"
            :obesidade="dadosAntropometriaGestante.OBESIDADE"
            :curva-ideal="dadosAntropometriaGestante.IDEAL"
            :curva-paciente="curvaPaciente"
            :filtro-valores-eixo-x="filtroValoresEixoX"
            :key="graficoKey"
          ></AntropometriaChart>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Cadastrar Gestação -->
    <v-dialog width="450px" v-model="modalAdicionarGestacao">
      <v-card v-if="carregandoGestacao">
        <v-card-text class="pa-5">
          <div class="text-center py-5">
            <v-progress-circular
              :size="70"
              :width="5"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h2 class="loading-titulo">Carregando</h2>
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <h1 class="titulo-modal">Nova Gestação</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <h2 class="pa-3 text-center">
            Preencha as informações abaixo para adicionar uma gestação e iniciar
            um acompanhamento gestacional para este paciente
          </h2>
          <v-form v-model="formValido">
            <v-row class="py-10">
              <v-col cols="12" lg="6">
                <CampoPeso
                  label="Peso pré gestacional"
                  dense
                  :autofocus="true"
                  required
                  v-model="gestacao.peso"
                  :rules="[(v) => !!v || 'Informação obrigatória']"
                >
                </CampoPeso>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="Altura/Estatura"
                  dense
                  v-model="gestacao.altura"
                  input-mode="decimal"
                  v-mask="'#.##'"
                  required
                  :rules="[(v) => !!v || 'Informação obrigatória']"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <date-picker
                  label="Última menstruação"
                  data-minima="1900-01-01"
                  dense
                  required
                  v-model="gestacao.dataUltimaMenstruacao"
                  @onChange="verificaIdadePorDataMenstruacao"
                >
                </date-picker>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="Idade Gestacional"
                  dense
                  required
                  v-model="gestacao.idade"
                  input-mode="decimal"
                  :rules="[(v) => !!v || 'Informação obrigatória']"
                  v-mask="'##'"
                  @keyup="validarQuantidadeSemanaGestacional(gestacao.idade)"
                  @blur="verificaDataMenstruacaoPelaIdadeGestacional"
                  hint="Máximo de 43 semanas"
                  persistent-hint
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-primario"
            :disabled="!formValido"
            text
            @click="cadastrarGestacao"
            >Salvar
          </v-btn>
          <v-btn
            @click="fecharModalAdicionarGestacao"
            class="btn-terciario"
            text
            >Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Cadastrar Antropometria -->
    <v-dialog max-width="550px" v-model="modalAdicionarAntropometria">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">
            {{
              modoEdicao
                ? "Editar avaliação antropométrica"
                : "Nova avaliação antropométrica"
            }}
          </h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-row class="pt-3">
            <v-col cols="12" lg="12">
              <h2 class="pa-3 text-center">
                Preencha as informações abaixo para realizar uma avaliação
                antropométrica
              </h2>
            </v-col>
            <v-col cols="12" lg="6">
              <v-autocomplete
                :items="listaDeSemanasGestacionais"
                label="Semana gestacional"
                item-text="descricao"
                item-value="semana"
                v-model="antropometria.semanaGestacional"
                clearable
                :autofocus="true"
                @change="verificaAntropometriaExistente"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3">
              <CampoPeso label="Peso" v-model="antropometria.peso"> </CampoPeso>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field
                input-mode="decimal"
                label="Altura"
                v-model="antropometria.altura"
                v-mask="'#.##'"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!modoEdicao"
            class="btn-primario"
            text
            @click="cadastrarAntropometria"
            >Salvar
          </v-btn>
          <v-btn
            v-if="modoEdicao"
            class="btn-primario"
            text
            @click="atualizarAntropometria"
            >Atualizar
          </v-btn>
          <v-btn
            @click="fecharModalAdicionarAntropometria"
            class="btn-terciario"
            text
            >Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <janela-confirmacao
      v-model="abrirJanelaConfirmacao"
      :callbackPrimario="callbackExcluirAntropometria"
      :mensagem="mensagemDeExclusao"
    />
  </v-card>
</template>

<script>
// Componentes
import JanelaConfirmacao from "@/components/comum/JanelaConfirmacao";
import DatePicker from "@/components/comum/Datepicker";

// Mixin
import MixinMensagem from "@/mixin/mixin-mensagem";
import MixinAutorizacao from "@/mixin/mixin-autorizacao";

// import ListaConsultaModal from "@/components/modais/consulta/ListaConsulta";
import AntropometriaChart from "@/components/graficos/AntropometriaChart.vue";
import CampoPeso from "@/components/comum/CampoPeso";

// import Editor from "@/components/comum/EditorTinymceNutri";
// import Template from "@/model/template/Template";
// import TabelaVazia from "@/components/comum/TabelaVazia";
import TelaVazia from "@/components/comum/TelaVazia";

import moment from "moment";

// API
// import apiPacientes from "@/api/paciente/paciente-api";
import apiGestacao from "@/api/gestante/gestacao-api";
import apiAntropometria from "@/api/gestante/antropometria-api";

// Constants
import { SEMANAS_GESTACIONAIS } from "@/constants/geral-constantes";
import { GESTANTE } from "@/constants/antropometrias-gestante";

// Models
// import PacienteModel from "@/model/paciente/paciente-model";
import GestacaoModel from "@/model/gestante/gestacao-model";
import AntropometriaModel from "@/model/gestante/antropometria-model";

// Utils
import utilsData from "@/utils/data";
// import utilsCalculos from "@/utils/calculos";

// Trackings
import mixpanel from "mixpanel-browser";

export default {
  name: "AntropometriaGestanteTab",
  mixins: [MixinMensagem, MixinAutorizacao],
  components: {
    CampoPeso,
    DatePicker,
    AntropometriaChart,
    JanelaConfirmacao,
    TelaVazia,
  },
  props: {
    paciente: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  emits: {
    "disparar-mensagem": (evento) => {
      return !!(evento.mensagem && evento.tipo);
    },
    "excluir-antropometria": (evento) => {
      return !!(evento.callback && evento.mensagem);
    },
    "fechar-janela-confirmacao": undefined,
  },
  watch: {
    paciente() {
      this.obterAntropometriasPaciente();
    },
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },
  computed: {
    dadosTabelaAntropometria() {
      let antropometrias = [].concat(this.antropometrias);
      // let indice = antropometrias.findIndex((a) => a.semanaGestacional == 1);
      // if (indice != -1) {
      //   antropometrias.splice(indice, 1);
      // }
      return antropometrias;
    },
  },
  mounted() {
    this.loader = true;
    this.obterGestacoes();
  },
  data() {
    return {
      formValido: true,
      carregandoGestacao: false,
      search: "",
      janelaAnamnese: false,
      desabilitarEditor: false,
      ordenacaoTabelaAntropometria: "semanaGestacional",
      // template: new Template(),
      gestacao: new GestacaoModel(),
      antropometria: new AntropometriaModel(),
      // paciente: new PacienteModel(),
      gestacoes: [],
      antropometrias: [],
      listaDeSemanasGestacionais: SEMANAS_GESTACIONAIS,
      gestacaoSelecionada: undefined,
      abrirJanelaConfirmacao: false,
      modalAdicionarGestacao: false,
      modalVisualizarGestacao: false,
      modalAdicionarAntropometria: false,
      mensagemDeExclusao: "",
      callbackConfirmarExclusao: () => {},
      anamneses: [],
      abrirJanela: false,
      loader: false,
      colunasAntropometrias: [
        {
          text: "Semana gestacional",
          value: "semanaGestacional",
          sortable: false,
          align: "left",
        },
        { text: "Peso", value: "peso", sortable: false, align: "center" },
        { text: "Altura", value: "altura", sortable: false, align: "center" },
        { text: "IMC", value: "imc", sortable: false, align: "center" },
        {
          text: "Data Criação",
          value: "criadoEm",
          sortable: false,
          align: "right",
        },
        { text: "", value: "botoes", sortable: false, align: "right" },
      ],
      colunas: [
        { text: "Gestação", align: "left", value: "peso", sortable: true },
        {
          text: "Última menstruação",
          align: "left",
          value: "peso",
          sortable: true,
        },
        { text: "Data de Criação", value: "criadoEm", sortable: true },
        { text: "", value: "botoes", align: "right", sortable: false },
      ],
      dadosAntropometriaGestante: GESTANTE,
      curvaPaciente: [],
      graficoKey: 0,
      modoEdicao: false,
    };
  },
  methods: {
    verificaIdadePorDataMenstruacao() {
      this.gestacao.idade = this.calculaSemanaGestacionalPorDataMenstruacao(
        this.gestacao
      );
    },

    // Método para descobrir a idade gestacional através da data de última menstruacao
    calculaSemanaGestacionalPorDataMenstruacao(gestacao) {
      if (gestacao.dataUltimaMenstruacao) {
        var dataAtual = new Date().getTime();
        var dataMenstruacao = new Date(
          gestacao.dataUltimaMenstruacao
        ).getTime();

        var totalDeDias = dataAtual - dataMenstruacao;

        //Converte dia em milisegundo em dia
        totalDeDias = (totalDeDias / 1000 / 60 / 60 / 24).toFixed(0);

        //Converte dias em semanas gestacionais
        var idadeGestacional = Math.round(totalDeDias / 7).toString();

        return idadeGestacional;
      }
    },

    validarQuantidadeSemanaGestacional(semanas) {
      if (!semanas) return;
      if (semanas > 43) {
        this.gestacao.idade = 43;
      }
    },

    // Método para descobrir a data de última menstruacao pela idade gestacional
    verificaDataMenstruacaoPelaIdadeGestacional() {
      var dataAtual = new Date();
      var totalDeDias = this.gestacao.idade * 7;

      // Abate a quantidade de dias da data atual.
      dataAtual.setDate(dataAtual.getDate() - totalDeDias);

      // Passa a nova data para data da ultima mestruação.
      this.gestacao.dataUltimaMenstruacao = new Date(dataAtual);
    },

    // Métodos da Gestação

    adicionarGestacao() {
      this.limparGestacao();
      this.modalAdicionarGestacao = true;
    },

    adicionarAntropometria() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_gestante",
        nome_botao: "btn_adicionar_antropometria_gestante",
        paciente_id: this.$route.params.id,
      });
      this.modoEdicao = false;
      this.modalAdicionarAntropometria = true;
      this.limparAntropometria();
    },

    obterGestacoes() {
      apiGestacao
        .obterPaciente(this.$route.params.id)
        .then((resposta) => {
          this.gestacoes = resposta.data.map((g) => {
            let gestacao = new GestacaoModel(g);
            this.adicionarNomeGestacao(gestacao);
            return gestacao;
          });
          this.gestacaoSelecionada = this.gestacoes[0] && this.gestacoes[0].id;

          if (this.gestacaoSelecionada) {
            this.obterAntropometrias(this.gestacaoSelecionada);
          }
        })
        .catch(() => {
          this.dispararMensagem(
            "Não foi possível obter as gestações deste paciente",
            "error"
          );
        });
    },

    cadastrarGestacao() {
      // TODO: Migrar esse calculo de IMC para um Utils
      this.calcularIMCGestacao();
      this.gestacao.paciente = this.paciente;
      this.gestacao.consulta = this.$store.state.consulta;
      this.carregandoGestacao = true;
      this.gestacao.idUsuario = parseInt(this.usuario.id);
      apiGestacao
        .cadastrar(this.gestacao)
        .then((resposta) => {
          this.adicionaNovaGestacaoAoArray(resposta.data);
          this.$emit("disparar-mensagem", {
            mensagem: "Gestação cadastrada com sucesso!",
            tipo: "success",
          });
          this.obterAntropometrias(this.gestacaoSelecionada);
          this.fecharModalAdicionarGestacao();
          this.carregandoGestacao = false;
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Preencha os campos obrigatórios",
            tipo: "error",
          });
          this.carregandoGestacao = false;
        });
    },

    adicionaNovaGestacaoAoArray(data) {
      let gestacao = new GestacaoModel(data);
      this.adicionarNomeGestacao(gestacao);
      this.gestacoes.unshift(gestacao);
      this.gestacaoSelecionada = gestacao.id;
    },

    adicionarNomeGestacao(gestacao) {
      gestacao.nome = `Semana ${this.calculaSemanaGestacionalPorDataMenstruacao(
        gestacao
      )} - DUM ${utilsData.aplicarMascaraEmDataIso(
        gestacao.dataUltimaMenstruacao
      )}`;
    },

    calcularIMCGestacao() {
      this.gestacao.peso = parseFloat(this.gestacao.peso);
      this.gestacao.altura = parseFloat(this.gestacao.altura);

      var imc =
        this.gestacao.peso / (this.gestacao.altura * this.gestacao.altura);

      this.gestacao.imc = parseFloat(imc.toPrecision(3));
    },

    visualizarGestacao(gestacao) {
      this.gestacao = new GestacaoModel(gestacao);
      this.antropetria;
      this.obterAntropometrias(this.gestacao.id);
      this.modalVisualizarGestacao = true;
      this.antropometria.gestacao = this.gestacao;
    },

    fecharModalAdicionarGestacao() {
      this.modoEdicao = false;
      this.modalAdicionarGestacao = false;
      this.limparGestacao();
    },

    // Métodos da Antropometria

    obterAntropometrias(idGestacao) {
      apiAntropometria
        .obterAntropometriaPorGestacao(idGestacao)
        .then((resposta) => {
          this.antropometrias = resposta.data.map(
            (a) => new AntropometriaModel(a)
          );
          this.preencherCurvaPaciente();
        })
        .catch((erro) => {
          this.$emit("disparar-mensagem", {
            mensagem: erro,
            tipo: "error",
          });
        });
    },

    preencherCurvaPaciente() {
      let curva = [];
      for (let i = 0; i < this.antropometrias.length; i++) {
        let semana = parseInt(this.antropometrias[i].semanaGestacional);
        curva[semana] = this.antropometrias[i].imc;
      }
      this.curvaPaciente = curva;
      this.graficoKey += 1;
    },

    cadastrarAntropometria() {
      this.calcularIMCAntropometria();
      this.antropometria.gestacao.id = this.gestacaoSelecionada;
      this.antropometria.consulta = this.$store.state.consulta;
      this.antropometria.idUsuario = parseInt(this.usuario.id);
      console.log(this.antropometria);
      apiAntropometria
        .cadastrar(this.antropometria)
        .then((resposta) => {
          this.antropometrias.push(new AntropometriaModel(resposta.data));
          this.$emit("disparar-mensagem", {
            mensagem: "Antropometria cadastrada com sucesso!",
            tipo: "success",
          });
          this.atualizarAntropometriaNaLista(
            new AntropometriaModel(resposta.data)
          );
          this.preencherCurvaPaciente();
          this.fecharModalAdicionarAntropometria();
        })
        .catch((erro) => {
          this.$emit("disparar-mensagem", {
            mensagem: erro,
            tipo: "error",
          });
        });
    },

    editarAntropometria(antropometria) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_gestante",
        nome_botao: "btn_editar_antropometria_gestante",
        paciente_id: this.$route.params.id,
      });
      this.antropometria = new AntropometriaModel(antropometria);
      this.modoEdicao = true;
      this.modalAdicionarAntropometria = true;
    },

    calcularIMCAntropometria() {
      this.antropometria.peso = parseFloat(this.antropometria.peso);
      this.antropometria.altura = parseFloat(this.antropometria.altura);

      var imc =
        this.antropometria.peso /
        (this.antropometria.altura * this.antropometria.altura);

      this.antropometria.imc = parseFloat(imc.toPrecision(3));
    },

    atualizarAntropometria() {
      this.calcularIMCAntropometria();
      apiAntropometria
        .atualizar(this.antropometria)
        .then((resposta) => {
          this.$emit("disparar-mensagem", {
            mensagem: "Antropometria atualizada com sucesso!",
            tipo: "success",
          });
          this.atualizarAntropometriaNaLista(
            new AntropometriaModel(resposta.data)
          );
          this.preencherCurvaPaciente();
          this.fecharModalAdicionarAntropometria();
        })
        .catch(() => {});
    },

    atualizarAntropometriaNaLista(antropometria, deletarDaLista) {
      var index = this.antropometrias
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(antropometria.id));
      !deletarDaLista
        ? this.antropometrias.splice(index, 1, antropometria) // Atualiza
        : this.antropometrias.splice(index, 1); // Deleta
    },

    excluirAntropometria(antropometria) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_gestante",
        nome_botao: "btn_excluir_antropometria_gestante",
        paciente_id: this.$route.params.id,
      });
      this.antropometria = new AntropometriaModel(antropometria);
      this.abrirJanelaConfirmacao = true;
      this.callbackExcluirAntropometria = this.deletarAntropometria;
      this.mensagemDeExclusao = `Deseja excluir o antropometria da semana &nbsp;<b>${this.antropometria.semanaGestacional}</b>?`;
    },

    deletarAntropometria() {
      apiAntropometria
        .deletar(this.antropometria)
        .then(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Antropometria excluida com sucesso!",
            tipo: "success",
          });
          this.atualizarAntropometriaNaLista(this.antropometria, true);
          this.preencherCurvaPaciente();
        })
        .catch(() =>
          this.$emit("disparar-mensagem", {
            mensagem: "Falha na exclusão da antropometria",
            tipo: "error",
          })
        )
        .finally((this.abrirJanelaConfirmacao = false));
    },

    fecharModalAdicionarAntropometria() {
      this.modalAdicionarAntropometria = false;
      this.limparAntropometria();
    },

    fecharVisualizarGestacao() {
      this.limparGestacao();
      this.modalVisualizarGestacao = false;
    },

    limparGestacao() {
      this.gestacao = new GestacaoModel();
    },

    limparAntropometria() {
      this.antropometria = new AntropometriaModel();
    },

    salvar() {
      !this.modoEdicao
        ? this.cadastrarAntropometria()
        : this.atualizarAntropometria();
    },

    verificaAntropometriaExistente() {
      if (this.modoEdicao) {
        return;
      }
      let semana = this.antropometria.semanaGestacional;
      let antropometria = this.antropometrias.find(
        (a) => a.semanaGestacional == semana
      );
      if (antropometria) {
        this.antropometria = Object.assign({}, antropometria);
      } else {
        this.antropometria = new AntropometriaModel();
        this.antropometria.semanaGestacional = semana;
        let antropometriaInicial = this.antropometrias.find(
          (a) => a.semanaGestacional == 0
        );
        this.antropometria.altura = Object.assign(
          {},
          antropometriaInicial
        ).altura;
      }
    },

    janelaConfirmacaoEvento(event) {
      this.abrirJanelaConfirmacao = true;
      this.callbackConfirmarExclusao = event.callback;
      this.mensagemDeExclusao = event.mensagem;
    },

    fecharJanelaConfirmacao() {
      this.abrirJanelaConfirmacao = false;
    },

    filtroValoresEixoX(label) {
      if (label % 2 == 0) {
        return label;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grafico-placeholder {
  width: 100%;
  height: 400px;
  background-color: $cor_primaria_sombra;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>