import axios from 'axios';
import { URLS } from '@/constants/configuracoes-constants';
function obterEnderecoPorCep(cep) {
  return new Promise((resolve, reject) => {
    axios.get(`${URLS.VIACEP}/${cep}/json/`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}


export default {
  obterEnderecoPorCep,
};
