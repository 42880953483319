import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { pt },
        current: 'pt',
    },
    theme: {
      themes: {
        light: {
          primary:'#C29879',
          secondary: '#3AAA35',
          accent: '#82B1FF',
          info: '#0090FF',
          warning:"#F4C711",
          error: '#FF533D',
          success: '#73bc49',
        },
      }
  }

});


//cor primaria #1543B7 
//cor secundaria #2562FF