<template>
  <div>
    <v-card flat v-if="prescricoes.length">
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12">
            <v-btn
              class="btn-primario mr-2"
              text
              @click="abrirModalAdicionarPrescricao"
            >
              <plus-icon size="1x" class="mr-2"></plus-icon>
              Nova Prescrição Dietética
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <v-data-table
              dense
              :items="prescricoes"
              :headers="colunasPrescricao"
              :items-per-page="10"
              sort-by="id"
              sort-desc
              class="elevation-0"
            >
              <template v-slot:[`item.getReferencia.gastoEnergeticoTotal`]="{ item }">
                {{ parseInt(item.getReferencia.gastoEnergeticoTotal) }}
              </template>
              <template v-slot:[`item.criadoEm`]="{ item }">
                {{ item.criadoEm | data }}
              </template>
              <template v-slot:[`item.botoes`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      @click="abrirModalEditarPrescricao(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <edit-2-icon size="1.1x"></edit-2-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      @click="duplicarPrescricao(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <copy-icon size="1.1x"></copy-icon>
                    </v-btn>
                  </template>
                  <span>Duplicar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      @click="excluir(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <trash-icon size="1.1x"></trash-icon>
                    </v-btn>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row v-else>
      <TelaVazia
        :imagemSrc="'prescricao-dietetica.svg'"
        :titulo="'Não há prescrição dietética para este paciente'"
        :textoBotaoPrimario="'Adicionar prescrição dietética'"
        :exibirBotaoPrimario="true"
        :eventoBotaoPrimario="abrirModalAdicionarPrescricao"
      >
      </TelaVazia>
    </v-row>

    <!-- Cadastrar Prescricao -->
    <v-dialog eager width="1245px" v-model="modalAdicionarPrescricao">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Prescrição Dietética</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="12">
              <v-stepper id="stepper" v-model="stepPrescricaoDietetica">
                <v-stepper-header class="pa-1">
                  <v-stepper-step
                    :complete="stepPrescricaoDietetica > 1"
                    step="1"
                  >
                    Informações
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="stepPrescricaoDietetica > 2"
                    step="2"
                  >
                    Alimentos
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- Informacoes iniciais prescricao -->
                  <v-stepper-content step="1">
                    <v-form id="cadastro" ref="form" validation v-model="formValido">
                      <v-row class="pt-2">
                        <v-col cols="12" lg="3">
                          <v-text-field
                            label="Nome da Prescrição"
                            dense
                            :rules="campoObrigatorio"
                            v-model="prescricao.descricao"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" lg="3">
                          <v-autocomplete
                            label="GET de referência"
                            return-object
                            item-value="id"
                            :item-text="(item) => montarLabelAutocompleteGet(item)"
                            :items="gets"
                            dense
                            v-model="prescricao.getReferencia"
                            clearable
                            :rules="campoObrigatorio"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="3">
                          <date-picker
                            label="Data Inicial"
                            data-minima="1900-01-01"
                            dense
                            :rules="campoObrigatorio"
                            required
                            v-model="prescricao.dataInicial"
                          >
                          </date-picker>
                        </v-col>
                        <v-col cols="12" lg="3">
                          <date-picker
                            label="Data Final"
                            :data-minima="prescricao.dataInicial || '1900-01-01'"
                            dense
                            :rules="campoObrigatorio"
                            required
                            v-model="prescricao.dataFinal"
                          >
                          </date-picker>
                        </v-col>
                        <v-col cols="12" lg="12">
                          <v-textarea
                            label="Observação"
                            dense
                            v-model="prescricao.observacao"
                            no-resize
                            rows="5"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-stepper-content>

                  <!-- Itens prescricao -->
                  <v-stepper-content step="2">
                    <v-row>
                      <v-col cols="12" lg="2">
                        <v-btn
                          class="btn-secundario mr-2"
                          text
                          @click="adicionarRefeicao"
                        >
                          <plus-icon size="1x" class="mr-2"></plus-icon>
                          Refeição
                        </v-btn>
                      </v-col>
                      <v-col v-for="(refeicao, i) in prescricao.refeicoes" cols="12" lg="12" :key="i">
                        <v-card>
                          <v-card-title>
                            <v-row justify="space-between">
                              <v-col cols="12" lg="4">
                                <v-autocomplete
                                  label="Refeição"
                                  :items="listaRefeicoes"
                                  v-model="refeicao.nome"
                                  clearable
                                  :rules="campoObrigatorio"
                                  dense
                                >
                                  <template v-slot:append-outer>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          text
                                          @click="removerRefeicao(i)"
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="prescricao.refeicoes.length <= 1"
                                        >
                                          <trash-icon size="1.1x"></trash-icon>
                                        </v-btn>
                                      </template>
                                      <span>Excluir</span>
                                    </v-tooltip>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" lg="2">
                                <v-btn
                                  class="btn-secundario mr-2"
                                  text
                                  @click="adicionarAlimento(i)"
                                  >
                                  <plus-icon size="1x" class="mr-2"></plus-icon>
                                  Alimento
                                </v-btn>
                              </v-col>
                              <v-col cols="12" lg="12">
                                <v-data-table
                                  dense
                                  :items="refeicao.alimentos"
                                  :headers="colunasAlimento"
                                  :items-per-page="10"
                                  class="elevation-0"
                                >
                                  <template v-slot:[`item.botoes`]="{ item }">
                                    <td class="text-right">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            icon
                                            text
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="editarAlimento(i, item)"
                                          >
                                            <edit-2-icon size="1.1x"></edit-2-icon>
                                          </v-btn>
                                        </template>
                                        <span>Editar</span>
                                      </v-tooltip>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            icon
                                            text
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="excluirAlimento(i, item)"
                                          >
                                            <trash-icon size="1.1x"></trash-icon>
                                          </v-btn>
                                        </template>
                                        <span>Excluir</span>
                                      </v-tooltip>
                                    </td>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <template v-if="prescricao.getReferencia">
            <v-col md="2">
              Calorias Totais: {{ parseInt(prescricao.getReferencia.gastoEnergeticoTotal) }}
            </v-col>
            <v-col md="2">
              Calorias Usadas: {{ caloriasUsadas }}
            </v-col>
            <v-col md="2">
              Calorias Restantes: {{ caloriasRestantes }}
            </v-col>
            <v-col md="2">
              Calorias Substituição: {{ caloriasUsadasAlternativo }}
            </v-col>
            <v-col md="2">
              Calorias Restantes Substituição: {{ caloriasRestantesAlternativo }}
            </v-col>
          </template>
          
          <v-col class="text-right">
            <v-btn
              v-if="stepPrescricaoDietetica < 2"
              text
              class="btn-primario mr-2"
              @click="stepPrescricaoDietetica++"
              :disabled="!formValido"
              >
              Avançar
            </v-btn>
            <v-btn
              v-else-if="!edicao"
              text
              class="btn-primario mr-2"
              :disabled="!validoParaSalvar"
              @click="criarPrescricao"
              >
              Salvar
            </v-btn>
            <v-btn
              v-else-if="edicao"
              text
              class="btn-primario mr-2"
              @click="editarPrescricao"
              :disabled="!validoParaSalvar"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-secundario"
              @click="modalAdicionarPrescricao = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Adicionar Alimento Refeicao -->
    <v-dialog eager width="600px" v-model="modalAdicionarAlimento">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Adicionar Alimento</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="12">
              <v-form id="cadastroAlimento" ref="formAlimento" validation v-model="formAlimentoValido">
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-autocomplete
                      label="Alimento"
                      return-object
                      item-value="id"
                      :item-text="(item) => item.descricao + ' - ' + item.kcalPorcao + 'kcal/100gr'"
                      :items="tabelaTaco"
                      dense
                      v-model="alimentoSelecionado"
                      clearable
                      :rules="campoObrigatorio"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                      label="Quantidade(gr)"
                      dense
                      v-model="novoAlimento.quantidadeGr"
                      type="number"
                      class="alinhar-direita"
                      :rules="campoObrigatorio"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                      label="KCal"
                      dense
                      type="number"
                      class="alinhar-direita"
                      :value="kCalAlimento"
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-autocomplete
                      label="Alimento Substituição"
                      return-object
                      item-value="id"
                      :item-text="(item) => item.descricao + ' - ' + item.kcalPorcao + 'kcal/100gr'"
                      :items="tabelaTaco"
                      dense
                      v-model="alimentoSelecionadoAlternativo"
                      clearable
                      :rules="campoObrigatorio"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                      label="Quantidade(gr)"
                      dense
                      v-model="novoAlimento.quantidadeGrAlternativo"
                      type="number"
                      class="alinhar-direita"
                      :rules="campoObrigatorio"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                      label="KCal"
                      dense
                      type="number"
                      class="alinhar-direita"
                      :value="kCalAlimentoAlternativo"
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primario mr-2"
              :disabled="!formAlimentoValido"
              @click="salvarAlimento"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-secundario"
              @click="modalAdicionarAlimento = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// OUTROS
import TelaVazia from "@/components/comum/TelaVazia";
import DatePicker from "@/components/comum/Datepicker";
import utilData from "@/utils/data";

// MODEL
import PrescricaoDieteticaModel from "@/model/prescricaoDietetica/prescricao-dietetica-model";
import PrescricaoDieteticaRefeicaoModel from "@/model/prescricaoDietetica/prescricao-dietetica-refeicao-model";
import PrescricaoDieteticaAlimentoModel from "@/model/prescricaoDietetica/prescricao-dietetica-alimento-model";
import AlimentoTacoModel from "@/model/prescricaoDietetica/alimento-taco-model";
import CalculoEnergeticoModel from "@/model/consulta/calculo-energetico-model";

// API
import apiTabelaTaco from "@/api/prescricaoDietetica/tabela-taco-api";
import apiPrescricaoDietetica from "@/api/prescricaoDietetica/prescricao-dietetica-api";
import apiCalculoEnergetico from "@/api/consulta/calculos-energeticos-api";

// Tracking
import mixpanel from "mixpanel-browser";

// Mixins
import mixinAutorizacao from "@/mixin/mixin-autorizacao";

export default {
  name: "PrescricaoDieteticaTab",
  mixins: [
    mixinAutorizacao,
  ],
  components: {
    TelaVazia,
    DatePicker,
  },
  props: {
    paciente: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: {
    "disparar-mensagem": (evento) => {
      return !!(evento.mensagem && evento.tipo);
    },
    "fechar-janela-confirmacao": undefined,
  },
  data() {
    return {
      prescricoes: [],
      tabelaTaco: [],
      gets: [],
      stepPrescricaoDietetica: 1,
      modalAdicionarPrescricao: false,
      prescricao: new PrescricaoDieteticaModel(),
      formValido: false,
      campoObrigatorio: [(v) => !!v || "Este campo é obrigatório"],
      edicao: false,
      novoAlimento: new PrescricaoDieteticaAlimentoModel(),
      alimentoSelecionado: new AlimentoTacoModel(),
      alimentoSelecionadoAlternativo: new AlimentoTacoModel(),
      refeicaoAtualIndex: 0,
      modalAdicionarAlimento: false,
      formAlimentoValido: false,
      prescricaoExclusao: undefined,
      colunasAlimento: [
        {
          text: "Alimento",
          value: "nomeTACO",
          sortable: true,
          align: "left",
        },
        {
          text: "KCal",
          value: "kCal",
          sortable: true,
          align: "left",
        },
        {
          text: "Quantidade(gr)",
          value: "quantidadeGr",
          sortable: true,
          align: "left",
        },
        {
          text: "Alimento Substituição",
          value: "nomeTACOAlternativo",
          sortable: true,
          align: "left",
        },
        {
          text: "KCal Substituição",
          value: "kCalAlternativo",
          sortable: true,
          align: "left",
        },
        {
          text: "Quantidade Substituição(gr)",
          value: "quantidadeGrAlternativo",
          sortable: true,
          align: "left",
        },
        {
          text: "",
          value: "botoes",
          sortable: false,
          align: "right", 
        },
      ],
      colunasPrescricao: [
        {
          text: "Nome",
          value: "descricao",
          sortable: true,
          align: "left",
        },
        {
          text: "Get Referência (KCal)",
          value: "getReferencia.gastoEnergeticoTotal",
          sortable: true,
          align: "left",
        },
        {
          text: "Data",
          value: "criadoEm",
          sortable: true,
          align: "left",
        },
        {
          text: "",
          value: "botoes",
          sortable: false,
          align: "right",
        },
      ],
      listaRefeicoes: ["Desjejum", "Café da manhã", "Lanche da manhã", "Almoço", "Lanche da tarde", "Jantar", "Ceia"],
      alimentoAtualIndex: undefined,
    };
  },

  computed: {

    validoParaSalvar() {
      if (!this.prescricao.refeicoes.length) {
        return false;
      }

      for (const refeicao of this.prescricao.refeicoes) {
        if (!refeicao.nome || !refeicao.alimentos.length) {
          return false;
        }
      }

      return true;
    },

    caloriasUsadas() {
      return this.prescricao.refeicoes.map(i => i.alimentos).flat().map(i => parseInt(i.kCal)).reduce((acc, cur) => acc +cur, 0);
    },

    caloriasRestantes() {
      return parseInt(this.prescricao.getReferencia.gastoEnergeticoTotal) - this.caloriasUsadas;
    },

    caloriasUsadasAlternativo() {
      return this.prescricao.refeicoes.map(i => i.alimentos).flat().map(i => parseInt(i.kCalAlternativo)).reduce((acc, cur) => acc + cur, 0);
    },

    caloriasRestantesAlternativo() {
      return parseInt(this.prescricao.getReferencia.gastoEnergeticoTotal) - this.caloriasUsadasAlternativo;
    },

    kCalAlimento() {
      return this.alimentoSelecionado?.kcalPorcao * this.novoAlimento?.quantidadeGr / 100 || 0;
    },

    kCalAlimentoAlternativo() {
      return this.alimentoSelecionadoAlternativo?.kcalPorcao * this.novoAlimento?.quantidadeGrAlternativo / 100 || 0;
    },

  },

  filters: {
    data(valor) {
      return utilData.aplicarMascaraEmDataIso(valor);
    },
  },

  mounted() {
    this.obterPrescricoes();
    this.obterGETs();
    this.oterTabelaTaco();
  },

  methods: {
    async abrirModalAdicionarPrescricao() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "prescricao_dietetica",
        nome_botao: "btn_adicionar_prescricao_dietetica",
        paciente_id: this.$route.params.id,
      });
      this.prescricao = new PrescricaoDieteticaModel();
      this.$refs.form && (await this.$refs.form.reset());
      this.stepPrescricaoDietetica = 1;
      this.edicao = false;
      this.modalAdicionarPrescricao = true;
    },
    adicionarRefeicao() {
      this.prescricao.refeicoes.push(new PrescricaoDieteticaRefeicaoModel());
    },
    removerRefeicao(indiceRefeicao) {
      this.prescricao.refeicoes.splice(indiceRefeicao, 1);
    },
    adicionarAlimento(indiceRefeicao) {
      this.refeicaoAtualIndex = indiceRefeicao;
      this.novoAlimento = new PrescricaoDieteticaAlimentoModel();
      this.alimentoSelecionado = new AlimentoTacoModel(); 
      this.alimentoSelecionadoAlternativo = new AlimentoTacoModel();
      this.alimentoAtualIndex = undefined;
      this.modalAdicionarAlimento = true;
    },
    salvarAlimento() {
      this.novoAlimento.idTACO = this.alimentoSelecionado.id;
      this.novoAlimento.nomeTACO = this.alimentoSelecionado.descricao;
      this.novoAlimento.kCal = this.alimentoSelecionado.kcalPorcao * this.novoAlimento.quantidadeGr / 100;

      this.novoAlimento.idTACOAlternativo = this.alimentoSelecionadoAlternativo.id;
      this.novoAlimento.nomeTACOAlternativo = this.alimentoSelecionadoAlternativo.descricao;
      this.novoAlimento.kCalAlternativo = this.alimentoSelecionadoAlternativo.kcalPorcao * this.novoAlimento.quantidadeGrAlternativo / 100;

      let alimento = new PrescricaoDieteticaAlimentoModel(this.novoAlimento);

      if (this.alimentoAtualIndex != undefined) {
        this.prescricao.refeicoes[this.refeicaoAtualIndex].alimentos.splice(this.alimentoAtualIndex, 1, alimento);
      } else {
        this.prescricao.refeicoes[this.refeicaoAtualIndex].alimentos.push(alimento);
      }

      this.modalAdicionarAlimento = false;
    },
    excluirAlimento(indiceRefeicao, alimento) {
      let index = this.prescricao.refeicoes[indiceRefeicao].alimentos.findIndex(a => a.idTACO == alimento.idTACO);
      this.prescricao.refeicoes[indiceRefeicao].alimentos.splice(index, 1);
    },
    editarAlimento(indiceRefeicao, alimento) {
      this.refeicaoAtualIndex = indiceRefeicao;
      this.alimentoAtualIndex = this.prescricao.refeicoes[indiceRefeicao].alimentos.findIndex(a => a.idTACO == alimento.idTACO);
      this.novoAlimento = new PrescricaoDieteticaAlimentoModel(alimento);
      this.alimentoSelecionado = this.tabelaTaco.find((i) => i.id == this.novoAlimento.idTACO);
      this.alimentoSelecionadoAlternativo = this.tabelaTaco.find((i) => i.id == this.novoAlimento.idTACOAlternativo);
      this.modalAdicionarAlimento = true;
    },
    criarPrescricao() {
      this.prescricao.idPaciente = this.paciente.id;
      this.prescricao.idConsulta = this.$store.state.consulta.id;
      this.prescricao.idUsuario = this.usuario.id;

      this.prescricao = new PrescricaoDieteticaModel(this.prescricao);

      apiPrescricaoDietetica
        .cadastrar(this.prescricao)
        .then((resposta) => {
          this.prescricoes.push(new PrescricaoDieteticaModel(resposta.data));
          this.modalAdicionarPrescricao = false;
          this.$emit("disparar-mensagem", {
            mensagem: "Prescrição criada com sucesso!",
            tipo: "success",
          });
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao criar a prescrição",
            tipo: "error",
          });
        });
    },
    obterPrescricoes() {
      apiPrescricaoDietetica
        .obterPorPaciente(this.paciente.id)
        .then((resposta) => {
          this.prescricoes = resposta.data.map(
            (p) => new PrescricaoDieteticaModel(p)
          );
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Não foi possível obter as prescrições",
            tipo: "error",
          });
        });
    },
    oterTabelaTaco() {
      apiTabelaTaco
        .obterTudo()
        .then((resposta) => {
          this.tabelaTaco = resposta.data.map(
            (a) => new AlimentoTacoModel(a)
          );
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Não foi possível obter os dados da tabela taco",
            tipo: "error",
          });
        });
    },
    obterGETs() {
      apiCalculoEnergetico
        .obterCalculoPorPaciente(this.paciente.id)
        .then((resposta) => {
          this.gets = resposta.data.map(
            (g) => new CalculoEnergeticoModel(g)
          );
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Não foi possível obter os GETs",
            tipo: "error",
          });
        });
    },

    async abrirModalEditarPrescricao(prescricao) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "prescricao_dietetica",
        nome_botao: "btn_editar_prescricao_dietetica",
        paciente_id: this.$route.params.id,
      });
      this.$refs.form && (await this.$refs.form.reset());

      apiPrescricaoDietetica
        .obterPorId(prescricao.id)
        .then((resposta) => {
          this.prescricao = new PrescricaoDieteticaModel(resposta.data);

          for (const refeicao of this.prescricao.refeicoes) {
            for (const alimento of refeicao.alimentos) {
              alimento.nomeTACO = this.tabelaTaco.find((i) => i.id == alimento.idTACO).descricao;
              alimento.nomeTACOAlternativo = this.tabelaTaco.find((i) => i.id == alimento.idTACOAlternativo).descricao;
            }
          }
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Não foi possível obter a prescrição de id " + prescricao.id,
            tipo: "error",
          });
        });

      this.edicao = true;
      this.modalAdicionarPrescricao = true;
    },
    editarPrescricao() {
      apiPrescricaoDietetica
        .atualizar(this.prescricao)
        .then((resposta) => {
          let indice = this.prescricoes.findIndex((p) => p.id == this.prescricao.id);
          this.prescricoes.splice(
            indice,
            1,
            new PrescricaoDieteticaModel(resposta.data)
          );
          this.modalAdicionarPrescricao = false;
          this.$emit("disparar-mensagem", {
            mensagem: "Prescrição atualizada com sucesso!",
            tipo: "success",
          });
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao editar a prescrição",
            tipo: "error",
          });
        });
    },
    excluir(prescricao) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "prescricao_dietetica",
        nome_botao: "btn_excluir_prescricao_dietetica",
        paciente_id: this.$route.params.id,
      });

      this.prescricaoExclusao = prescricao.id;
      this.$emit("excluir-prescricao", {
        callback: this.callbackExcluirPrescricao,
        mensagem: `Deseja excluir a prescrição dietética?`,
      });
    },
    callbackExcluirPrescricao() {
      apiPrescricaoDietetica
        .deletar(this.prescricaoExclusao)
        .then(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Prescrição excluída com sucesso",
            tipo: "success",
          });
          let indice = this.prescricoes.findIndex(
            (p) => p.id == this.prescricaoExclusao
          );
          this.prescricoes.splice(indice, 1);
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Falha na exclusão da prescrição",
            tipo: "error",
          });
        })
        .finally(() => {
          this.$emit("fechar-janela-confirmacao");
          this.prescricaoExclusao = undefined;
        });
    },
    montarLabelAutocompleteGet(get) {
      return `${utilData.aplicarMascaraEmDataIso(get.criadoEm)} - ${get.formula} - ${parseInt(get.gastoEnergeticoTotal)}kcal/dia`;
    },
    async duplicarPrescricao(prescricao){
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "prescricao_dietetica",
        nome_botao: "btn_duplicar_prescricao_dietetica",
        paciente_id: this.$route.params.id,
      });
      this.$refs.form && (await this.$refs.form.reset());

      apiPrescricaoDietetica
        .obterPorId(prescricao.id)
        .then((resposta) => {
          this.prescricao = new PrescricaoDieteticaModel(resposta.data);
          this.prescricao.descricao = `Cópia ${this.prescricao.descricao}`;
          this.prescricao.id = null;

          for (const refeicao of this.prescricao.refeicoes) {
            for (const alimento of refeicao.alimentos) {
              alimento.nomeTACO = this.tabelaTaco.find((i) => i.id == alimento.idTACO).descricao;
            }
          }
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Não foi possível obter a prescrição de id " + prescricao.id,
            tipo: "error",
          });
        });

      this.edicao = false;
      this.stepPrescricaoDietetica = 1;
      this.modalAdicionarPrescricao = true;
    }
  },
};
</script>

<style lang="scss" scoped>

.v-stepper__header::v-deep, .v-stepper::v-deep {
  box-shadow: 0 0;
}
</style>