import Login from '@/views/Login.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import ControleDePaciente from '@/views/paciente/ControleDePaciente.vue';
import ProntuarioPaciente from '@/views/paciente/ProntuarioPaciente.vue';
import Agenda from '@/views/agenda/Agenda.vue';
import ControleDeUsuario from '@/views/usuario/ControleDeUsuario.vue'
import Assinatura from '@/views/assinatura/Assinatura.vue'
import ConfirmacaoAssinatura from '@/views/assinatura/ConfirmacaoAssinatura.vue'
import ControleDeModelosDeDocumento from '@/views/modelo/ControleDeModelosDeDocumento.vue'
import CadastroDeModelosDeDocumento from '@/views/modelo/CadastroDeModelosDeDocumento.vue';
import NovoDocumento from "../views/paciente/prontuario-tabs/documentos/NovoDocumento.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    title: "Login",
    meta: { requireAuth: false }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    title: "Dashboard",
    meta: { requireAuth: true }
  },
  {
    path: "/agenda",
    name: "agenda",
    component: Agenda,
    title: "agenda",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-modelos",
    name: "controle-modelos",
    component: ControleDeModelosDeDocumento,
    title: "Modelos de documento",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-modelos/cadastro",
    name: "controle-modelo-cadastro",
    component: CadastroDeModelosDeDocumento,
    title: "Cadastro de Modelo",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-modelos/:id",
    name: "controle-modelo-edicao",
    component: CadastroDeModelosDeDocumento,
    title: "Edição de Modelo",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-paciente",
    name: "controle-paciente",
    component: ControleDePaciente,
    title: "Controle de Paciente",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-paciente/:id/prontuario",
    name: "prontuario-paciente",
    component: ProntuarioPaciente,
    title: "Prontuario Paciente",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-paciente/:idPaciente/prontuario/documento/:idDocumento",
    name: "prontuario-paciente-editar-documentos",
    component: NovoDocumento,
    title: "Editar Documento",
    meta: { requireAuth: true }
  },
  {
    path: "/controle-paciente/:idPaciente/prontuario/documento/",
    name: "prontuario-paciente-cadastrar-documentos",
    component: NovoDocumento,
    title: "Novo Documento",
    meta: { requireAuth: true }
  },
  {
    path: "/assinatura/nova-assinatura",
    name: "assinatura",
    component: Assinatura,
    title: "Assinatura",
    icon: "",
    meta: {},
  },
  {
    path: "/assinatura/nova-assinatura/confirmacao",
    name: "confirmacao-assinatura",
    component: ConfirmacaoAssinatura,
    title: "Confirmação assinatura",
    icon: "",
    meta: {},
  },
  {
    path: "/controle-usuario",
    name: "controle-usuario",
    component: ControleDeUsuario,
    title: "Controle de Usuário",
    meta: { requireAuth: true }
  },
];

export default routes;