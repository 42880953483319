import TipoAgendamentoModel from './tipo-agendamento-model';
import UsuarioModel from '../usuario-model';
import PacienteModel  from '../paciente/paciente-model';
import ClassificacaoModel  from './classificacao-model';
import ProcedimentoModel  from './procedimento-model';

export default class AgendamentoModel {
    
    constructor(obj){
        obj = obj || {};
        this.id = obj.id;
        this.tipoAgendamento = new TipoAgendamentoModel(obj.tipoAgendamento);
        this.usuario = new UsuarioModel(obj.usuario);
        this.procedimento = new ProcedimentoModel(obj.procedimento);
        this.classificacao = new ClassificacaoModel(obj.classificacao);
        this.paciente = new PacienteModel(obj.paciente);
        this.descricao = obj.descricao;
        this.observacao = obj.observacao;
        this.dataHoraInicial = obj.dataHoraInicial;
        this.dataHoraFinal = obj.dataHoraFinal;
        this.diaInteiro = obj.diaInteiro || false;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }

    modeloValidoParaCadastro(){
        let erros = [];
        this._validaCamposObrigatoriosPreenchidos(erros);
        this._validaCamposObrigatorisConsulta(erros);

        return erros;
    }

    _validaCamposObrigatoriosPreenchidos(erros){
        let validacao = this.usuario && this.usuario.id &&
                        this.tipoAgendamento && this.tipoAgendamento.id && 
                        this.dataHoraInicial && this.dataHoraFinal;
        
        if(!validacao){
            erros.push( 
                `Os campos nutricionista, Tipo de agendamento, data inicial e final, Hora inicial e final são obrigatórios.`);
        }
    }

    _validaCamposObrigatorisConsulta(erros){
        let validacaoConsultas = this.procedimento && this.procedimento.id &&
                                 this.classificacao && this.classificacao.id &&
                                 this.paciente && this.paciente.id;

        // tipo de agendamento de id = 2 é consulta, como definido nas migrations
        if (this.tipoAgendamento.id == 2 && !validacaoConsultas) {

            erros.push(`Os campos procedimento, classificação e paciente são obrigatórios quando o tipo de agendamento é consulta.`);
        }
    }


}