'use strict';

import moment from 'moment';

function aplicarMascaraEmDataIso(data, valorDataVazia) {
  if (data) {
    return moment(data).format('DD/MM/YYYY');
  }

  return (valorDataVazia) ? valorDataVazia : null;
}

function aplicarMascaraDataHoraEmDataIso(data, valorDataVazia) {
  if (data) {
    return moment(data).format('DD/MM/YYYY HH:mm:ss');
  }

  return (valorDataVazia) ? valorDataVazia : null;
}


function aplicarMascaraDataHoraSemSegundos(data, valorDataVazia) {
  if (data) {
    return moment(data).format('DD/MM/YYYY HH:mm');
  }

  return (valorDataVazia) ? valorDataVazia : null;
}
function formatarDataEHoraIso(data, horario) {
  return `${moment(data).format('YYYY-MM-DD')}T${horario || '00:00:00'}`;
}

function dataFinalMaiorOuIgualDataInicial(dataInicial, dataFinal) {
  return !dataInicial || (dataInicial == '') ||
    !dataFinal || (dataFinal == '') ||
    (new Date(dataInicial) <= new Date(dataFinal));
}

function aplicarMascaraHoraSemSegundos(data, valorDataVazia) {
  if (data) {
    return moment(data).locale('pt-br').format('HH:mm');
  }

  return (valorDataVazia) ? valorDataVazia : null;
}

function calculaIdade(dataNasc, dataReferencia) {
  if (!dataNasc) return 0;

  dataNasc = new Date(dataNasc);

  dataReferencia = dataReferencia || new Date();

  var anoAtual = dataReferencia.getFullYear();
  var diaNasc = dataNasc.getDate();
  var mesNasc = dataNasc.getMonth() + 1; // Janeiro é 0, por isso + 1 para janeiro ser o mês 1
  var anoNasc = dataNasc.getFullYear();
  var idade = anoAtual - anoNasc;
  var mesAtual = dataReferencia.getMonth() + 1;

  //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
  if (mesAtual < mesNasc) {
    idade--;
  } else {
    //Se estiver no mes do nascimento, verificar o dia
    if (mesAtual == mesNasc) {
      if (new Date().getDate() < diaNasc) {
        //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
        idade--;
      }
    }
  }
  return idade;
}

function calcularIdadeAnoMesesDias(dataNasc) {
  let anos = calculaIdade(dataNasc);
  let meses = calcularIdadeMes(dataNasc);

  if (anos == 0) {
    let dias = _calcularIdadeDia(dataNasc);
    return `${meses} mes(es) e ${dias} dia(s)`;
  }

  return `${anos} ano(s) e ${meses} mes(es)`;
}

function calcularIdadeMes(dataNasc, dataReferencia) {
  dataReferencia = dataReferencia || new Date();

  if (!dataNasc) return 0;

  dataNasc = new Date(dataNasc)

  let meses = undefined;
  var diaNasc = dataNasc.getDate();
  var mesNasc = dataNasc.getMonth() + 1; // Janeiro é 0, por isso + 1 para janeiro ser o mês 1
  let mesAtual = dataReferencia.getMonth() + 1;
  let diaAtual = dataReferencia.getDate();

  if (mesAtual < mesNasc) {
    meses = 12 - (mesNasc - mesAtual);
  } else if (mesAtual > mesNasc) {
    meses = mesAtual - mesNasc;
  } else {
    meses = 12;
  }

  if (diaNasc > diaAtual) {
    meses--;
  }

  if (meses == 12) {
    meses = 0;
  }

  return meses;
}

function _calcularIdadeDia(dataNasc) {

  if (!dataNasc) return 0;

  let dataAtual = new Date();
  dataNasc = new Date(dataNasc);

  return dataAtual.getDate() - dataNasc.getDate();
}

export default {
  dataFinalMaiorOuIgualDataInicial,
  aplicarMascaraDataHoraEmDataIso,
  aplicarMascaraDataHoraSemSegundos,
  formatarDataEHoraIso,
  aplicarMascaraEmDataIso,
  aplicarMascaraHoraSemSegundos,
  calculaIdade,
  calcularIdadeAnoMesesDias,
  calcularIdadeMes
};