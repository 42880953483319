export default class AnexoModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.idPaciente = obj.idPaciente;
    this.idUsuario = parseInt(obj.idUsuario);
    this.descricao = obj.descricao;
    this.caminho = obj.caminho;
    this.tamanho = obj.tamanho;
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  modeloValido() {
    let erros = [];

    let valido = this.descricao;

    if (!valido) {
      erros.push(`O campos descrição é obrigatório.`);
    }

    return erros;
  }

}