import PrescricaoDieteticaAlimentoModel from './prescricao-dietetica-alimento-model';

export default class PrescricaoDieteticaRefeicaoModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.nome = obj.nome;
    this.alimentos = obj.alimentos?.map(alimento => new PrescricaoDieteticaAlimentoModel(alimento)) || [];
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  modeloValido() {
    
  }
}
