<template>
  <v-container>
    <AreaChart
      ref="chartArea"
      :chart-data="chartData"
      :options="optionsBar"
    ></AreaChart>
  </v-container>
</template>

<script>
import AreaChart from "@/components/graficos/AreaChart.vue";
export default {
  name: "AntropometriaChart",
  props: {
    //Titulo do grafico
    titulo: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    // Meses do paciente 0 a 24 por exemplo no primeiro grafico
    idade: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },

    // Classificações | magreza acentuada
    // [10.1, 11,2]
    magrezaAcentuada: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    magreza: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    eutrofia: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    riscoSobrePeso: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    sobrepeso: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    obesidade: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },

    //linha montada dinamicamente de acordo com as marcações do paciente.
    curvaPaciente: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },

    // TODO: Descobrir o que é isso...
    filtroValoresEixoX: {
      type: Function,
      required: false,
      default: (label) => {
        return label;
      },
    },
  },
  components: {
    AreaChart,
  },
  data() {
    return {
      optionsBar: {
        // faz que, caso um valor no meio do dataset seja nulo, a grafico preencha até o próximo ponto
        spanGaps: true,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
        elements: {
          line: {
            borderWidth: 0.5,
          },
          point: {
            radius: 0,
            hoverRadius: 0,
          },
        },
        legend: {
          // desabilita o click da legenda (ativar e desativar os itens do gráfico)
          onClick: null,
          labels: {
            usePointStyle: true,
          },
        },
        title: {
          display: true,
          text: this.titulo,
        },
        scales: {},
        plugins: {
          datalabels: {
            textAlign: "center",
            font: {
              weight: "bold",
              size: 12,
            },
            color: "white",
          },
        },
      },
      chartData: {
        labels: this.idade,
        datasets: [
          {
            label: "IMC",
            borderColor: "black",
            pointBackgroundColor: "black",
            pointBorderColor: "white",
            fill: true,
            data: this.curvaPaciente,
            pointRadius: 5,
            pointHoverRadius: 5,
          },
          {
            label: "Magreza acentuada",
            borderColor: "#9FA8DA",
            backgroundColor: "#9FA8DA",
            data: this.magrezaAcentuada,
          },
          {
            label: "Magreza",
            borderColor: "#81D4FA",
            backgroundColor: "#81D4FA",
            data: this.magreza,
          },
          {
            label: "Eutrofia",
            borderColor: "#AED581",
            backgroundColor: "#AED581",
            data: this.eutrofia,
          },
          {
            label: "Risco sobrepeso",
            borderColor: "#FFF176",
            backgroundColor: "#FFF176",
            data: this.riscoSobrePeso,
          },
          {
            label: "Sobrepeso",
            borderColor: "#FFB74D",
            backgroundColor: "#FFB74D",
            data: this.sobrepeso,
          },
          {
            label: "Obesidade",
            borderColor: "#FF8A65",
            backgroundColor: "#FF8A65",
            data: this.obesidade,
            fill: "end",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style> 