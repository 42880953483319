export default class Template{
    constructor(obj){
        obj = obj || {};
        this.id = obj.id;
        this.descricao = obj.descricao;
        this.tipo = obj.tipo;
        this.valor = obj.valor;
        this.valorOriginal = obj.valor;
        this.principal= obj.principal;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }
    
    modeloValido(){
        return !!(this.descricao && this.tipo && this.valor);
    }
}