export default class TipoAgendamentoModel {
    
    constructor(obj){
        obj = obj || {};
        this.id = obj.id;
        this.descricao = obj.descricao;
        this.cor = obj.cor;
        this.prioridade = obj.prioridade;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
    }

}