import Vue from 'vue';
import Vuex from 'vuex';
import ConsultaModel from '../model/consulta/consulta-model';
import AnamneseModel from '../model/consulta/anamnese-model';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    consulta: new ConsultaModel(),
    anamnese: new AnamneseModel()
  },
  mutations: {
    criarConsulta(state, consulta){
      state.consulta = new ConsultaModel(consulta);
    },
    limparConsulta(state){
      state.consulta = new ConsultaModel();
    },
    criarAnamnese(state, anamnese){
      state.anamnese = new AnamneseModel(anamnese);
    },
    limparAnamnese(state){
      state.anamnese = new AnamneseModel();
    },
    atualizarDescricaoAnamnese(state, descricao){
      state.anamnese.descricao = descricao;
    },
  },
  actions: {
  },
  modules: {
  }
})
