<template>
  <v-row justify="center">
    <!-- eslint-disable-next-line vue/no-mutating-props -->
    <v-dialog v-model="abrirJanela" max-width="500">
      <v-card class="pa-5">
        <v-card flat class="titulo-confirmacao" v-html="mensagem">
        </v-card>
        <v-card flat class="botoes-confirmacao">
          <v-btn
            @click="callbackPrimario"
            class="btn-primario mr-3"
            text
            >
            {{ textoBotaoPrimario }}
          </v-btn>
          <v-btn
            @click="fecharJanela"
            text
            class="btn-secundario"
            >
            {{ textoBotaoSecundario }}
          </v-btn>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  name: "AlteracaoStatus",
  model: {
    prop: "abrirJanela",
    event: "onChange",
  },
  data() {
    return {
      janelaAberta: this.abrirJanela,
    };
  },
  props: {
    mensagem: { type: String, default: "Confirma a alteração do status?" },
    textoBotaoPrimario: { type: String, default: "Sim" },
    textoBotaoSecundario: { type: String, default: "Não" },
    abrirJanela: { type: Boolean, default: false },
    callbackPrimario: { type: Function, default: () => {} },
    // callbackSecundario: { type: Function, default: ()=> {}},
    idBotaoPrimario: { type: String, default: "" },
    idBotaoSecundario: { type: String, default: "" },
  },

  methods: {
    fecharJanela() {
      this.janelaAberta = false;
      this.$emit("onChange", this.janelaAberta);
    },

  },
};
</script>
<style lang="scss" scoped>
.v-card__title {
  justify-content: center;
  align-content: center;
}
div.v-card__text {
  margin-top: 15px;
  text-align: end;
}
.theme--light.v-btn{
  color:none
}
.titulo-confirmacao{
  padding-bottom: 20px;
  text-align: center;
  color:rgb(48, 48, 48);
  font-size: 1.5em;
}
.botoes-confirmacao{
  text-align: center;
}
</style>
