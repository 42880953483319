import api from '../api'

function obterTudo(){
  return  new Promise((resolve, reject) =>{
    return api.get(`/taco`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

export default {
  obterTudo,
}