import axios from 'axios';
import utilsStorage from '@/utils/storage';
import { URLS } from '@/constants/configuracoes-constants';

const api = axios.create({
  baseURL: URLS.BASE
})

api.interceptors.request.use(async (config) => {
    var token = await utilsStorage.obterTokenNaStorage();
    config.headers.Authorization = token;
    return config;
});

api.interceptors.response.use((response) => {
    if(response.status === 401 && response.config.url !== "/login"){
        utilsStorage.removerAutenticacao();

        setTimeout(() =>{
            window.location.href = '/login';
        }, 1000);
    }
    return response;
},(error) => {

    if(!error.response){
      return Promise.reject(`Não foi possível se conectar com o servidor. 
      Se esta mensagem continuar aparecendo, entre em contato com nosso suporte técnico.`);
    }
    
    if(error.response.status === 401 && error.response.config.url !== "/login"){
        utilsStorage.removerAutenticacao();

        setTimeout(() =>{
            window.location.href = '/login';
        }, 10000);

        return Promise.reject(`Seu login de acesso expirou, efetue o login novamente para acessar o sistema.`);
    }

    return Promise.reject(error);
});

export default api;