import GestacaoModel from '../gestante/gestacao-model';
import ConsultaModel from '../consulta/consulta-model';

export default class AntropometriaModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.gestacao = new GestacaoModel(obj.gestacao)
    this.semanaGestacional = obj.semanaGestacional;

    this.imc = obj.imc;
    this.altura = obj.altura;
    this.peso = obj.peso;
    this.consulta = new ConsultaModel(obj.consulta);
    this.idUsuario = parseInt(obj.idUsuario);

    this.dataInativacao = obj.dataInativacao;
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  modeloValido() {
    return !!(this.imc && this.altura && this.peso && this.id);
  }
}