<template>
  <div class="ma-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="0"
      multi-line
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <div v-if="loading" class="loading text-center">
      <v-progress-circular
        :size="70"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <h1 class="loading-titulo">Carregando</h1>
    </div>

    <v-row v-if="!loading">
      <v-col cols="12" lg="12">
        <h1>Controle de Usuarios</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" lg="10">
        <v-btn class="btn-primario ml-0" text @click="adicionarUsuario">
          <v-icon class="mr-2 icone">mdi-plus</v-icon>
          Adicionar</v-btn
        >
      </v-col>

      <v-col cols="12" lg="2">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="12">
        <v-card class="card-cuitatto">
          <v-data-table
            :headers="colunas"
            :items="usuarios"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="10"
          >
            <template v-slot:[`item.criadoEm`]="{ item }">
              {{ item.criadoEm | data }}
            </template>
            <template v-slot:[`item.dataInativacao`]="{ item }">
              {{ item.dataInativacao | data }}
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <td class="coluna-botoes text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      @click="editarUsuario(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <edit-2-icon
                        size="1.3x"
                        class="custom-class"
                      ></edit-2-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip v-if="item.idPerfil != 1" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      @click="excluirUsuario(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <trash-icon size="1.3x" class="custom-class">
                      </trash-icon>
                    </v-btn>
                  </template>
                  <span>Excluir ou Inativar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      min-height="800px"
      max-width="800px"
    >
      <v-card flat>
        <v-card-title>
          <h1 class="titulo-modal">
            {{ !modoEdicao ? "Adicionar " : "Editar " }} usuário
          </h1>
        </v-card-title>

        <v-card-text>
          <v-divider></v-divider>
          <v-container>
            <form autocomplete="off">
              <v-row class="mt-2">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="usuario.nome"
                    label="Nome"
                    autocomplete="off"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="usuario.email"
                    label="E-mail"
                    autocomplete="off"
                    type="email"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-autocomplete
                    :items="perfils"
                    label="Perfil"
                    v-model="usuario.perfil"
                    item-text="descricao"
                    return-object
                    clearable
                    autocomplete="off"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Senha"
                    v-model="usuario.senha"
                    :append-icon="value ? 'visibility_off' : 'visibility'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    :rules="[
                      (v) =>
                        v.trim().length >= 6 ||
                        'Senha deve ter mínimo de 6 caracteres',
                    ]"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <date-picker
                    label="Data de inativação"
                    hint="Opcional"
                    v-model="usuario.dataInativacao"
                    dense
                    :dataMinima="dataMinima"
                  >
                  </date-picker>
                </v-col>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primario mr-2"
              :disabled="!this.usuario.modeloValido() || desabilitarBotoes"
              @click="salvar"
            >
              Salvar
            </v-btn>
            <v-btn text class="btn-secundario" @click="cancelar">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <janela-confirmacao
      v-model="abrirJanelaConfirmacao"
      :callbackPrimario="callbackConfirmarExclusao"
      :mensagem="mensagemDeExclusao"
      @onChange="limparCampos"
    />
  </div>
</template>
<script>
import DatePicker from "@/components/comum/Datepicker";
import apiUsuario from "@/api/usuario/usuario-api";
import UsuarioModel from "@/model/usuario-model";
import mixinMensagen from "@/mixin/mixin-mensagem";
import JanelaConfirmacao from "@/components/comum/JanelaConfirmacao";
import { PERFIS } from "@/constants/geral-constantes";
import utilsData from "@/utils/data";
import utilsStore from "@/utils/storage";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "ControleDeUsuario",
  mixins: [mixinMensagen],
  components: {
    JanelaConfirmacao,
    DatePicker,
  },
  filters: {
    data(valor) {
      return utilsData.aplicarMascaraEmDataIso(valor);
    },
    dataHora(valor) {
      return utilsData.aplicarMascaraDataHoraEmDataIso(valor);
    },
  },
  data() {
    return {
      teste: false,
      value: "password",
      file: undefined,
      dialog: false,
      loading: true,
      modoEdicao: false,
      exibirFiltros: false,
      desabilitarBotoes: false,
      abrirJanelaConfirmacao: false,
      mensagemDeExclusao: "",
      search: "",
      colunas: [
        { text: "Código", value: "id", sortable: false },
        { text: "Nome", value: "nome", align: "left", sortable: true },
        { text: "E-mail", value: "email", align: "left", sortable: false },
        { text: "Perfil", value: "perfil.descricao", sortable: false },
        { text: "Data de cadastro", value: "criadoEm", align: "center" },
        {
          text: "Data de inativação",
          value: "dataInativacao",
          align: "center",
        },
        { text: "", value: "acoes", sortable: false },
      ],
      usuario: new UsuarioModel(),
      usuarios: [],
      callbackConfirmarExclusao: () => {},
      perfils: PERFIS,
    };
  },
  mounted() {
    mixpanel.track("page_view", { nome_pagina: "controle_usuarios" });

    this.obterListaDeUsuarios();
  },
  computed: {
    dataMinima() {
      return new Date().toISOString();
    },
  },
  methods: {
    adicionarUsuario() {
      this.usuario = new UsuarioModel();
      this.dialog = true;
      mixpanel.track("click", {
        nome_pagina: "controle_usuarios",
        nome_botao: "btn_novo_usuario",
      });
    },
    obterListaDeUsuarios() {
      apiUsuario
        .obterTodos()
        .then((response) => {
          this.usuarios = response.data.map((u) => new UsuarioModel(u));

          this.limparCampos();
          this.loading = false;
        })
        .catch((erro) => {
          this.dispararMensagem(
            (erro.response && erro.response.data.mensagem) || erro,
            "error"
          );
          this.loading = false;
        });
    },

    salvar() {
      !this.modoEdicao ? this.cadastrarUsuario() : this.atualizarUsuario();
    },

    editarUsuario(usuario) {
      mixpanel.track("click", {
        nome_pagina: "controle_usuarios",
        nome_botao: "btn_icone_editar_usuario",
      });
      this.modoEdicao = true;
      this.usuario = new UsuarioModel(usuario);
      this.dialog = true;
    },

    cancelar() {
      this.dialog = false;
      this.usuario = new UsuarioModel();
      this.modoEdicao = false;
    },

    limparCampos() {
      this.usuario = new UsuarioModel();
    },
    cadastrarUsuario() {
      this.modoEdicao = false;
      this.desabilitarBotoes = true;
      let usuario = new UsuarioModel(this.usuario);
      usuario.idPerfil = this.usuario.perfil.id;

      let usuarioLogado = utilsStore.obterUsuarioAtualStorage();
      usuario.guidAssinante = usuarioLogado.guidAssinante;

      apiUsuario
        .cadastrar(usuario)
        .then((response) => {
          this.dispararMensagem(
            `Usuário cadastrado efetuado com sucesso!`,
            "success"
          );
          this.usuarios.push(new UsuarioModel(response.data));
          this.limparCampos();
          this.dialog = false;
          this.desabilitarBotoes = false;
        })
        .catch((erro) => {
          this.dispararMensagem(
            (erro.response && erro.response.data.mensagem) || erro,
            "error"
          );
          this.desabilitarBotoes = false;
        });
    },
    atualizarUsuario() {
      this.modoEdicao = true;
      this.desabilitarBotoes = true;
      let usuario = new UsuarioModel(this.usuario);
      usuario.idPerfil = this.usuario.perfil.id;

      apiUsuario
        .atualizar(usuario)
        .then((response) => {
          this.dispararMensagem(
            `${this.usuario.email} foi atualizado(a) com sucesso!`,
            "success"
          );
          this.atualizarUsuarioNaLista(new UsuarioModel(response.data));
          this.limparCampos();
          this.dialog = false;
          this.desabilitarBotoes = false;
        })
        .catch((erro) => {
          this.dispararMensagem(
            (erro.response && erro.response.data.mensagem) || erro,
            "error"
          );
          this.desabilitarBotoes = false;
        });
    },

    excluirUsuario(usuario) {
      mixpanel.track("click", {
        nome_pagina: "controle_usuarios",
        nome_botao: "btn_icone_excluir_usuario",
      });
      this.usuario = new UsuarioModel(usuario);
      this.mensagemDeExclusao = `Deseja realmente excluir a usuário &nbsp; <strong>${usuario.email}</strong>? <br> <br>
      <span class="informativo">Se ele já movimentou alguma informação no sistema, ele será inativado.<span>`;
      this.callbackConfirmarExclusao = this.deletarUsuario;
      this.abrirJanelaConfirmacao = true;
    },
    deletarUsuario() {
      apiUsuario
        .deletar(this.usuario)
        .then((response) => {
          let usuario = new UsuarioModel(response.data);

          this.dispararMensagem(
            `O ${this.usuario.email} foi ${
              usuario.dataInativacao ? "inativado" : "deletado"
            } com sucesso!`,
            "success"
          );

          usuario.dataInativacao
            ? this.atualizarUsuarioNaLista(this.usuario, false)
            : this.atualizarUsuarioNaLista(this.usuario, true);
        })
        .catch((erro) =>
          this.dispararMensagem(erro.response.data.mensagem, "error")
        )
        .finally((this.abrirJanelaConfirmacao = false));
    },
    atualizarUsuarioNaLista(usuario, deletarDaLista) {
      var index = this.usuarios
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(usuario.id));
      !deletarDaLista
        ? this.usuarios.splice(index, 1, usuario)
        : this.usuarios.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.informativo {
  font-size: 11px;
  font-style: italic;
}
</style>
