<template>
  <v-main class="fundo">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="0"
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-row class="my-10">
      <v-col>
        <v-card :loading="loading" class="box-login" elevation="10">
          <form class="form-login" autocomplete="off">
            <v-col class="logo" cols="12" col="12">
              <img
                src="../assets/svg/logotipo.svg"
                alt="Logotipo"
                class="pt-5"
                width="90%"
              />
            </v-col>
            <!-- Tela Login -->
            <v-col v-if="telaLogin" cols="12" class="m-0 p-0">
              <v-text-field
                autofocus
                color="primary"
                label="Email"
                v-model="usuario.email"
              ></v-text-field>
              <v-text-field
                @keyup.enter="logar"
                label="Senha"
                v-model="usuario.senha"
                :append-icon="value ? 'visibility_off' : 'visibility'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              ></v-text-field>
              <v-btn @click="logar" text class="btn-primario btn-block">
                Entrar
              </v-btn>
              <div class="lembrar-senha pt-2">
                <a @click="_mudarParaEsqueciSenha()">Esqueci minha senha</a>
              </div>
              <v-col>
                <v-divider class="pt-5"></v-divider>
              </v-col>
              <div class="nova-conta">
                <p>Ainda não possui uma conta?</p>
              </div>
              <v-btn class="btn-terciario" text @click="criarConta">
                Criar sua conta grátis!
              </v-btn>
            </v-col>

            <!-- Tela Esqueci Senha -->
            <v-col v-if="telaEsqueciSenha" cols="12" class="m-0 p-0">
              <h3 class="pb-3">
                Preencha seu e-mail para enviarmos o código para redefinir sua
                senha
              </h3>
              <v-text-field
                color="primary"
                label="Email"
                v-model="usuario.email"
              ></v-text-field>
              <div class="lembrar-senha pt-2">
                <v-btn @click="enviarCodigoPorEmail()" class="btn-primario" text
                  >Enviar código de confirmação
                </v-btn>
                <v-spacer class="py-1"></v-spacer>
                <v-btn @click="_mudarParaLogin()" class="btn-terciario" text
                  >Voltar
                </v-btn>
              </div>
            </v-col>

            <!-- Tela Código Confirmação -->
            <v-col v-if="telaCodigoDeConfirmacao" cols="12" class="m-0 p-0">
              <h3 class="pb-3">
                Digite o código recebido no e-mail {{ this.usuario.email }}
              </h3>
              <v-text-field
                color="primary"
                label="Código de Confirmação"
                v-model="usuario.codigo"
              ></v-text-field>
              <v-btn
                @click="validarCodigoRecebido()"
                class="btn-primario btn-block"
                text
                >Confirmar
              </v-btn>

              <v-spacer class="py-1"></v-spacer>

              <v-btn
                @click="_mudarParaEsqueciSenha()"
                class="btn-terciario"
                text
                >Voltar
              </v-btn>
            </v-col>

            <!-- Tela Nova Senha -->
            <v-col v-if="telaNovaSenha" cols="12" class="m-0 p-0">
              <h3 class="pb-3">Escolha sua nova senha.</h3>
              <v-text-field
                label="Nova Senha"
                required
                :append-icon="value ? 'visibility_off' : 'visibility'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
                v-model="usuario.novaSenha"
                autofocus
              ></v-text-field>
              <v-text-field
                label="Confirme sua senha"
                required
                :rules="[(v) => !!v || 'Confirme a nova senha']"
                :append-icon="value ? 'visibility_off' : 'visibility'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
                v-model="usuario.novaSenhaConfirmacao"
              ></v-text-field>

              <v-btn
                @click="redefinirSenha()"
                class="btn-primario btn-block"
                text
                >Salvar
              </v-btn>

              <v-spacer class="py-1"></v-spacer>
            </v-col>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
// Apis
import apiUsuario from "@/api/usuario/usuario-api";
// Utils
import utilsStorage from "@/utils/storage";
// Mixins
import MixinMensagem from "@/mixin/mixin-mensagem";

// Models
import UsuarioModel from "@/model/usuario-model";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "login",
  mixins: [MixinMensagem],
  data() {
    return {
      loading: false,
      usuario: new UsuarioModel(),
      value: "password",
      telaLogin: true,
      telaEsqueciSenha: false,
      telaCodigoDeConfirmacao: false,
      telaNovaSenha: false,
    };
  },
  mounted() {
    mixpanel.track("page_view", { nome_pagina: "login" });
  },
  methods: {
    criarConta() {
      mixpanel.track("click", {
        nome_pagina: "login",
        nome_botao: "btn_criar_conta",
      });
      window.location.href = `/assinatura/nova-assinatura/?plano=2`;
    },

    _mudarParaLogin() {
      this.telaLogin = true;
      mixpanel.track("click", {
        nome_pagina: "login",
        nome_botao: "btn_voltar_login",
      });
      this.telaEsqueciSenha = false;
      this.telaCodigoDeConfirmacao = false;
      this.telaNovaSenha = false;
    },

    _mudarParaEsqueciSenha() {
      mixpanel.track("click", {
        nome_pagina: "login",
        nome_botao: "btn_esqueci_senha",
      });
      this.telaLogin = false;
      this.telaEsqueciSenha = true;
      this.telaCodigoDeConfirmacao = false;
      this.telaNovaSenha = false;
    },

    _mudarParaCodigoDeConfirmacao() {
      mixpanel.track("click", {
        nome_pagina: "login",
        nome_botao: "btn_enviar_codigo",
      });
      this.telaLogin = false;
      this.telaEsqueciSenha = false;
      this.telaCodigoDeConfirmacao = true;
      this.telaNovaSenha = false;
    },

    _mudarParaNovaSenha() {
      mixpanel.track("click", {
        nome_pagina: "login",
        nome_botao: "btn_alterar_senha",
      });
      this.telaLogin = false;
      this.telaEsqueciSenha = false;
      this.telaCodigoDeConfirmacao = false;
      this.telaNovaSenha = true;
    },

    enviarCodigoPorEmail() {
      apiUsuario
        .esqueceuSenha(this.usuario.email)
        .then(() => {
          this.dispararMensagem(
            "Enviamos o código para o e-mail informado",
            "success"
          );
          this._mudarParaCodigoDeConfirmacao();
        })
        .catch(() => {
          this.dispararMensagem("Falha no envio do código por e-mail", "error");
        });
    },

    validarCodigoRecebido() {
      mixpanel.track("click", { nome_botao: "validar_codigo" });
      apiUsuario
        .validarUsuarioPorCodigo(this.usuario.email, this.usuario.codigo)
        .then((resposta) => {
          this.salvarInformacoesLocalStorage(
            resposta.data.usuario,
            resposta.data.token
          );
          this._mudarParaNovaSenha();
        })
        .catch(() => {
          this.dispararMensagem("Falha na validação do código", "error");
        });
    },

    redefinirSenha() {
      apiUsuario
        .redefinirSenha(this.usuario.novaSenha)
        .then(() => {
          this.dispararMensagem("Senha alterada com sucesso", "success");
          this.$router.push({ name: "agenda" });
        })
        .catch(() => {
          this.dispararMensagem(
            "Falha na redefinição de senha, verifique os campos",
            "error"
          );
        });
    },

    logar() {
      mixpanel.track("click", {
        nome_pagina: "login",
        nome_botao: "btn_entrar",
      });

      if (!this.usuario.email || !this.usuario.senha) {
        this.dispararMensagem("Informe o e-mail e senha para acesso.", "error");
        return;
      }
      this.loading = true;
      apiUsuario
        .logar(this.usuario)
        .then((response) => {
          this.loading = false;
          this.inicializarTracks(response);

          this.salvarInformacoesLocalStorage(
            response.data.usuario,
            response.data.token
          );
          this.$router.push({ name: "agenda" });
        })
        .catch((error) => {
          this.dispararMensagem(
            (error.response &&
              error.response.data &&
              error.response.data.mensagem) ||
              "Não foi possível se comunicar com o servidor!",
            "error"
          );
          utilsStorage.removerAutenticacao();
          this.loading = false;
        });
    },

    inicializarTracks(response) {
      window.pendo.initialize({
        visitor: {
          id: response.data.usuario.id,
          email: response.data.usuario.email,
          full_name: response.data.usuario.nome,
          role: response.data.usuario.idPerfil,
        },
        account: {
          guid: response.data.usuario.guidAssinante,
        },
      });
      mixpanel.identify(response.data.usuario.id);
      mixpanel.people.set({
        Id: response.data.usuario.id,
        Nome: response.data.usuario.nome,
        Email: response.data.usuario.email,
        PrimeiroLogin: new Date(),
        Perfil: response.data.usuario.idPerfil,
      });
      return;
    },

    salvarInformacoesLocalStorage(usuario, token) {
      utilsStorage.salvarTokenNaStorage(token);
      utilsStorage.salvarUsuarioNaStorage(usuario);
    },
  },
};
</script>

<style lang="scss" scoped>
.fundo {
  background: linear-gradient(
      rgba(194, 152, 122, 0.651),
      rgba(206, 179, 160, 0.651)
    ),
    url(../../src/assets/img/bg-login.jpg) !important;
  background-size: cover !important;
}
.box-login {
  width: 350px;
  text-align: center;
  border-radius: 10px;
  margin: 0 auto;
}
.form-login {
  width: 100%;
  padding: 15px;
}
.lembrar-senha {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
</style>