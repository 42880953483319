<template>
  <div>
    <div class="d-none d-sm-flex">
      <v-btn
        v-if="!$store.state.consulta.id"
        text
        id="btn-iniciar"
        class="btn-primario"
        @click="abrirModalNovaConsulta"
        ><activity-icon size="2x" class="pr-2"></activity-icon> Iniciar Consulta
      </v-btn>
      <div id="btn-encerrar" v-else>
        <img
          src="../../../assets/gif/consulta-ativa.gif"
          class="status-consulta"
        />
        <!-- <v-icon small class="mr-2 mb-1 iconeConsulta">mdi-circle</v-icon> -->
        <h3 class="mr-4">Consulta em Andamento</h3>

        <v-btn
          text
          id="btn-encerrar-consulta"
          class="btn-primario mr-4"
          @click="encerrarConsulta"
        >
          <activity-icon size="2x" class="pr-2"></activity-icon>
          Encerrar Consulta
        </v-btn>
      </div>
    </div>

    <div id="btn-encerrar-sm" class="d-flex d-sm-none">
      <v-btn
        v-if="!$store.state.consulta.id"
        icon
        id="btn-iniciar"
        @click="abrirModalNovaConsulta"
      >
        <activity-icon size="1.5x"></activity-icon>
      </v-btn>
      <div v-else>
        <img
          src="../../../assets/gif/consulta-ativa.gif"
          class="status-consulta"
        />
        <v-btn
          icon
          id="btn-encerrar-consulta"
          color="white"
          @click="encerrarConsulta"
        >
          <activity-icon size="1.5x"></activity-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="modalNovaConsulta"
      persistent
      scrollable
      min-height="600px"
      max-width="980px"
    >
      <v-card flat>
        <v-card-title>
          <h1 class="titulo-modal">Nova consulta</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="12">
              <v-form id="cadastro" ref="form" validation v-model="formValido">
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-autocomplete
                      label="Procedimento"
                      :items="listaTiposDeProcedimentos"
                      dense
                      item-text="descricao"
                      return-object
                      v-model="consulta.procedimento"
                      clearable
                      :rules="campoObrigatorio"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-autocomplete
                      label="Classificação"
                      :items="listaTiposDeClassificacao"
                      dense
                      item-text="descricao"
                      return-object
                      v-model="consulta.classificacao"
                      clearable
                      :rules="campoObrigatorio"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-autocomplete
                      v-model="consulta.paciente"
                      label="Paciente"
                      :items="listaDePacientes"
                      return-object
                      item-text="codigoNome"
                      clearable
                      dense
                      :rules="campoObrigatorio"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primario mr-2"
              @click="criarConsulta"
              :disabled="!formValido"
            >
              Iniciar consulta
            </v-btn>
            <v-btn
              text
              class="btn-secundario"
              @click="modalNovaConsulta = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Api
import apiPacientes from "@/api/paciente/paciente-api";
import apiClassificacoes from "@/api/agendamento/classificacoes-api";
import apiProcedimentos from "@/api/agendamento/procedimentos-api";
import apiConsulta from "@/api/consulta/consultas-api";

// Tracking
import mixpanel from "mixpanel-browser";

// Models
import ConsultaModel from "@/model/consulta/consulta-model";
import ClassificacaoModel from "@/model/agendamento/classificacao-model";
import ProcedimentoModel from "@/model/agendamento/procedimento-model";
import PacienteModel from "@/model/paciente/paciente-model";

export default {
  name: "CadastroConsulta",
  props: {
    agendamento: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      modalNovaConsulta: false,
      consulta: {},
      listaDePacientes: [],
      listaTiposDeProcedimentos: [],
      listaTiposDeClassificacao: [],
      formValido: false,
      campoObrigatorio: [(v) => !!v || "Este campo é obrigatório"],
    };
  },

  mounted() {
    this.obterTodosPacientes();
    this.obterClassificacoes();
    this.obterProcedimentos();
    if (this.agendamento) {
      this.consulta.procedimento = this.agendamento.procedimento ?? undefined;
      this.consulta.classificacao = this.agendamento.classificacao ?? undefined;
      this.consulta.paciente = this.agendamento.paciente ?? undefined;
      this.consulta.agendamento = this.agendamento ?? undefined;
    }
  },
  methods: {
    obterProcedimentos() {
      apiProcedimentos
        .obterTodos()
        .then((resposta) => {
          this.listaTiposDeProcedimentos = resposta.data.map(
            (p) => new ProcedimentoModel(p)
          );
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },

    obterClassificacoes() {
      apiClassificacoes
        .obterTodos()
        .then((resposta) => {
          this.listaTiposDeClassificacao = resposta.data.map(
            (c) => new ClassificacaoModel(c)
          );
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },

    obterTodosPacientes() {
      apiPacientes
        .obterTodos()
        .then((resposta) => {
          this.listaDePacientes = resposta.data.map(
            (p) => new PacienteModel(p)
          );
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },
    criarConsulta() {
      mixpanel.track("click", {
        nome_botao: "btn_iniciar_consulta",
      });
      this.consulta = new ConsultaModel(this.consulta);
      apiConsulta
        .cadastrar(this.consulta)
        .then((resposta) => {
          this.consulta = new ConsultaModel(resposta.data);
          this.$store.commit("criarConsulta", this.consulta);
          this.modalNovaConsulta = false;
          this.$router.push({
            name: "prontuario-paciente",
            params: { id: this.consulta.paciente.id },
          });
          this.consulta = {};
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },

    encerrarConsulta() {
      mixpanel.track("click", {
        nome_botao: "btn_encerrar_consulta",
      });
      this.$eventBus.$emit("encerrando-consulta");
      let consulta = this.$store.state.consulta;
      consulta.encerrada = true;
      apiConsulta
        .atualizar(consulta)
        .then(() => {
          this.$eventBus.$emit("consulta-encerrada");
          this.$store.commit("limparConsulta");
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },

    async abrirModalNovaConsulta() {
      this.$refs.form && (await this.$refs.form.reset());
      let id = this.$route.params.id;
      if (id) {
        this.consulta.paciente = this.listaDePacientes.find((p) => p.id == id);
      }
      this.modalNovaConsulta = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#btn-encerrar {
  width: 100%;
  display: flex;
  align-items: center;
}

#btn-encerrar-sm::v-deep * {
  display: flex;
  align-items: center;
}

.iconeConsulta {
  color: $green-500;
}

#btn-encerrar-consulta {
  background-color: $red-800;
}
.status-consulta {
  max-height: 55px;
  // margin: auto 0;
  // padding: 4px;
  display: block;
  position: relative;
}
.barra-consulta-mobile {
  position: absolute;
  display: block;
  width: 100%;
  background: red;
}
</style>
