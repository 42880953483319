import api from './api'

function obterTodos(){
    return  new Promise((resolve, reject) =>{
        return api.get('/tiposcontato')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

function obter(id){
    return  new Promise((resolve, reject) =>{
        return api.get(`/tiposcontato/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
}

export default {
    obterTodos,
    obter
}