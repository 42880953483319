import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  if (to.name == "Assinatura" ||
    to.name == "Login" ||
    to.name == "ConfirmacaoAssinatura") {
    next();
  } else if (to.matched.some(record => record.meta.requireAuth)) {

    if (localStorage.getItem('token-cuitatto') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    if (localStorage.getItem('token-cuitatto') == null) {
      next();
    } else {
      next({ name: 'Dashboard' })
    }
  }
});



export default router

