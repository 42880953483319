import api from '../api';

function obterTodos(){
    return new Promise((resolve, reject) => {
        return api.get('/agendamentos')
            .then(response => resolve(response))
            .catch(error => reject(error))
    });
}

function obterTiposDeAgendamento(){
    return new Promise((resolve, reject) => {
        return api.get('/tiposagendamento')
            .then(response => resolve(response))
            .catch(error => reject(error))
    });
}


function cadastrar(agendamento){
    agendamento = agendamento || {};
    return  new Promise((resolve, reject) =>{
      return api.post('/agendamentos', agendamento)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}
function atualizar(agendamento){
    agendamento = agendamento || {};
    return  new Promise((resolve, reject) =>{
      return api.put('/agendamentos/'+ agendamento.id, agendamento)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}


function deletar(idAgendamento){
      return  new Promise((resolve, reject) =>{
      return api.delete('/agendamentos/'+ idAgendamento)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}

export default {
    obterTodos,
    obterTiposDeAgendamento,
    cadastrar,
    atualizar,
    deletar
}