import api from '../api'

  function logar(usuario){
    usuario = usuario || {};
    return  new Promise((resolve, reject) =>{
      return api.post('/login', {email: usuario.email, senha: usuario.senha})
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
  }

  function deslogar(){
    return  new Promise((resolve, reject) =>{
      return api.delete('/logout')
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
  }

  function obterTodos(){
    return  new Promise((resolve, reject) =>{
      return api.get('/usuarios')
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
  }

  function cadastrar(usuario){
    usuario = usuario || {};
    return  new Promise((resolve, reject) =>{
      return api.post('/usuarios', usuario)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
  }

  function atualizar(usuario){
    usuario = usuario || {};
    return  new Promise((resolve, reject) =>{
        return api.put(`/usuarios/${usuario.id}`, usuario)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  }

  function deletar(usuario){
    usuario = usuario || {};
    return  new Promise((resolve, reject) =>{
        return api.delete(`/usuarios/${usuario.id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  }

  function atualizarPerfil(usuario, senhaAtual, novaSenha){
    usuario = usuario || {};
    return  new Promise((resolve, reject) =>{
        return api.post(`/usuarios/${usuario.id}/gerenciar-perfil`, {
          usuario,
          senhaAtual,
          novaSenha
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  }

  function redefinirSenha(senha){
    return  new Promise((resolve, reject) =>{
        return api.put(`/redefinirsenha`, {
          senha
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  }
  
  function esqueceuSenha(email){
    return  new Promise((resolve, reject) =>{
        return api.post(`/esqueceusenha`, {
          email
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  }

  function validarUsuarioPorCodigo(email, codigo){
    return  new Promise((resolve, reject) =>{
        return api.post(`/logincodigo`, {
          email, codigo
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  }

export default {
  logar,
  deslogar,
  obterTodos,
  cadastrar,
  atualizar,
  deletar,
  atualizarPerfil,
  redefinirSenha,
  esqueceuSenha,
  validarUsuarioPorCodigo,
}