<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="verConsultas()"
          icon
          v-bind="attrs"
          v-on="on"
          block
          text
          class="btn-secundario"
        >
          <activity-icon size="1.5x" class="mr-2"></activity-icon> Consultas
        </v-btn>
      </template>
      <span>Histórico de consultas</span>
    </v-tooltip>
    <v-dialog
      v-model="modalVisualizarConsultas"
      persistent
      scrollable
      min-height="600px"
      max-width="980px"
    >
      <v-card flat>
        <v-card-title>
          <h1 class="titulo-modal">Consultas realizadas</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="sem-registro" v-show="!this.consultas.length">
            <h1 class="titulo-sem-registro">Não há consultas</h1>
          </div>
          <v-row v-show="this.consultas.length">
            <v-col cols="12" lg="12">
              <v-data-table
                dense
                :items="consultas"
                :headers="colunasConsultas"
                :items-per-page="10"
                class="elevation-0"
                sort-by="id"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td class="text-center">
                        {{ item.criadoEm | data }}
                      </td>
                      <td class="text-left">
                        {{ item.usuario.nome }}
                      </td>
                      <td class="text-center">
                        {{ item.classificacao.descricao }}
                      </td>
                      <td class="text-center">
                        {{ item.procedimento.descricao }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-secundario"
              @click="modalVisualizarConsultas = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

// Apis
import apiConsulta from "@/api/consulta/consultas-api";

// Models
import ConsultaModel from "@/model/consulta/consulta-model";
import PacienteModel from "@/model/paciente/paciente-model";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "ListaConsulta",
  props: {
    paciente: {
      type: Object,
      default: () => {
        return new PacienteModel();
      },
    },
  },
  data() {
    return {
      consultas: [],
      modalVisualizarConsultas: false,
      colunasConsultas: [
        {
          text: "Data Criação",
          value: "criadoEm",
          sortable: true,
          align: "center",
        },
        {
          text: "Nutricionista",
          value: "usuario.nome",
          sortable: true,
          align: "left",
        },
        {
          text: "Classificação",
          value: "classificacao.descricao",
          sortable: true,
          align: "center",
        },
        {
          text: "Procedimento",
          value: "procedimento.descricao",
          sortable: true,
          align: "center",
        },
      ],
    };
  },

  beforeUpdate() {
    this.obterConsultas();
  },

  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY HH:mm");
    },
  },
  methods: {
    verConsultas() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        nome_botao: "btn_ver_consultas",
      });
      this.modalVisualizarConsultas = true;
    },
    obterConsultas() {
      if (this.consultas[0]) {
        return;
      }

      apiConsulta
        .obterConsultaPorPaciente(this.paciente.id)
        .then((resposta) => {
          this.consultas = resposta.data.map((c) => new ConsultaModel(c));
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>