class PrescricaoDieteticaAlimentoModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.idTACO = obj.idTACO;
    this.nomeTACO = obj.nomeTACO;
    this.quantidadeGr = obj.quantidadeGr;
    this.kCal = obj.kCal;
    this.idTACOAlternativo = obj.idTACOAlternativo;
    this.nomeTACOAlternativo = obj.nomeTACOAlternativo;
    this.quantidadeGrAlternativo = obj.quantidadeGrAlternativo;
    this.kCalAlternativo = obj.kCalAlternativo;
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  modeloValido() {
    
  }
}
module.exports = PrescricaoDieteticaAlimentoModel