export default class Assinante {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.nome = obj.nome;
    this.email = obj.email;
    this.senha = obj.senha;
    this.celular = obj.celular;
    this.cpfOuCnpj = obj.cpfOuCnpj;
    this.pessoaFisica = obj.pessoaFisica;
    this.aceitaOsTermos = obj.aceitaOsTermos;
    this.idPlano = obj.idPlano;
    this.cupomDesconto = obj.cupomDesconto;
    this.profissao = obj.profissao;
    this.instituicaoEnsino = obj.instituicaoEnsino;
    this.crmOuCrn = obj.crmOuCrn;
    this.responsavelTecnico = obj.responsavelTecnico;
    this.cep = obj.cep;
    this.rua = obj.rua;
    this.numero = obj.numero;
    this.complemento = obj.complemento;
    this.bairro = obj.bairro;
    this.cidade = obj.cidade;
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  ativoParaAssinatura() {
    return !!(
      this.nome &&
      this.email &&
      this.celular &&
      this.cpfOuCnpj &&
      this.senha &&
      this.aceitaOsTermos
    );
  }
}