
import api from '../api'

export default {

  obterTodos() {
    return new Promise((resolve, reject) => {
      return api.get('/tiposmodelos')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
};
