import AgendamentoModel from '../agendamento/agendamento-model';
import ClassificacaoModel from '../agendamento/classificacao-model';
import ProcedimentoModel from '../agendamento/procedimento-model';
import PacienteModel from '../paciente/paciente-model';
import UsuarioModel from '../usuario-model';

export default class ConsultaModel {
    constructor(obj){
        obj = obj || {};
        this.id = obj.id;
        this.paciente = new PacienteModel(obj.paciente);
        this.usuario = new UsuarioModel(obj.usuario);
        this.agendamento = new AgendamentoModel(obj.agendamento);
        this.classificacao = new ClassificacaoModel(obj.classificacao);
        this.procedimento = new ProcedimentoModel(obj.procedimento);
        this.encerrada = obj.encerrada || false;
        this.criadoEm = obj.criadoEm;
        this.atualizadoEm = obj.atualizadoEm;
        this.dataInativacao = obj.dataInativacao;
    }

    modeloValidoParaCadastro(){
        let erros = [];
        this._validacao();
        return erros;
    }

    modeloValidoParaAtualizacao(){
        let erros = [];
        this._validacao();
        return erros;
    }

    _validacao(erros){
        let validacao = this.paciente && this.paciente.id &&
                        this.classificacao && this.classificacao.id &&
                        this.procedimento && this.procedimento.id;

        if(!validacao){
            erros.push(400, "Os campos id do paciente, id da classificação e id do procedimento são obrigatórios.");
        }
    }

}