<template>
    <v-container>
        <AreaChart
            ref="chartArea"
            :chart-data="chartData"
            :options="optionsBar"
        ></AreaChart>
    </v-container>
</template>

<script>
import AreaChart from "@/components/graficos/AreaChart.vue";
export default {
    name: "AntropometriaChart",
    props: {
        //Titulo do grafico
        titulo: { type: String, required: false, default: () => { return ''; } },
        // Meses do paciente 0 a 24 por exemplo no primeiro grafico
        eixoX: { type: Array, required: true, default: () => { return []; } },

       // Classificações | magreza acentuada
       // [10.1, 11,2]
        baixoPeso: { type: Array, required: true, default: () => { return []; } },

        adequado: { type: Array, required: true, default: () => { return []; } },

        sobrepeso: { type: Array, required: true, default: () => { return []; } },

        obesidade: { type: Array, required: true, default: () => { return []; } },

        curvaIdeal: { type: Array, required: true, default: () => { return []; } },

        curvaPaciente: { type: Array, required: true, default: () => { return []; } },

        filtroValoresEixoX: { type: Function, required: false, default: (label) => { return label; } },
    },
    components: {
        AreaChart,
    },
    data() {
        return {
            optionsBar: {
                // faz que, caso um valor no meio do dataset seja nulo, a grafico preencha até o próximo ponto
                spanGaps: true,
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    animateRotate: false,
                },
                elements: {
                    line: {
                        borderWidth: 1,
                    },
                    point: {
                        radius: 0,
                        hoverRadius: 0,
                    },
                },
                legend: {
                    // desabilita o click da legenda (ativar e desativar os itens do gráfico)
                    onClick: null,
                    labels: {
                        usePointStyle: false,
                    },
                },
                title: {
                    display: true,
                    text: this.titulo,
                },
                scales: {
                    yAxes: [
                        {
                            display: true,
                            ticks: {
                                beginAtZero: true
                            },
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                userCallback: this.filtroValoresEixoX
                            }
                        },
                    ],
                },
                plugins: {
                    datalabels: {
                        textAlign: "center",
                        font: {
                            weight: "bold",
                            size: 12,
                        },
                        color: "white",
                    },
                },
            },
            chartData: {
                labels: this.eixoX,
                datasets: [
                    {
                        label: "Curva do paciente",
                        borderColor: "black",
                        pointBackgroundColor: "black",
                        pointBorderColor: "white",
                        fill: false,
                        data: this.curvaPaciente,
                        pointRadius: 5,
                        pointHoverRadius: 5,
                    },
                    {
                        label: "Baixo peso",
                        borderColor: "#ea9999",
                        backgroundColor: "#ea9999",
                        data: this.baixoPeso,
                    },
                    {
                        label: "Adequado",
                        borderColor: "#b6d7a8",
                        backgroundColor: "#b6d7a8",
                        data: this.adequado,
                    },
                    {
                        label: "Sobrepeso",
                        borderColor: "#fff2cc",
                        backgroundColor: "#fff2cc",
                        data: this.sobrepeso,
                    },
                    {
                        label: "Obesidade",
                        borderColor: "#f9cb9c",
                        backgroundColor: "#f9cb9c",
                        data: this.obesidade,
                    },
                    {
                        label: "Curva ideal",
                        borderColor: "darkGreen",
                        fill: false,
                        data: this.curvaIdeal,
                    },
                ],
            },
        };
    },
    mounted() {
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
</style> 