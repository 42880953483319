import api from '../api';

function obterTodos(idPaciente){
    return new Promise((resolve, reject) => {
        return api.get(`/pacientes/${idPaciente}/anexos`)
            .then(response => resolve(response))
            .catch(error => reject(error))
    });
}


function cadastrar(formData){
    formData = formData || {};
    return  new Promise((resolve, reject) =>{
      return api({
            method: 'post',
            url: '/anexos',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}

function atualizar(anexo){
    anexo = anexo || {};
    return  new Promise((resolve, reject) =>{
      return api.put('/anexos/'+ anexo.id, anexo)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}


function deletar(idAnexo){
      return  new Promise((resolve, reject) =>{
      return api.delete('/anexos/'+ idAnexo)
      .then(response => resolve(response))
      .catch(error => reject(error))
    });
}

function baixar(idAnexo){
  return  new Promise((resolve, reject) =>{
    return api.get('/anexos/'+ idAnexo + '/download', { responseType: 'blob' })
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

export default {
    obterTodos,
    cadastrar,
    atualizar,
    deletar,
    baixar,
}