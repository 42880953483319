<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-show="loading">
      <v-col cols="12" lg="6">
        <v-skeleton-loader
          class="mx-4"
          type="heading"
          min-width="100px"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" lg="12">
        <v-divider class="mx-4"></v-divider>
        <v-skeleton-loader type="article" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        ></v-skeleton-loader>
        <v-skeleton-loader type="article" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="article" min-width="100px"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-form ref="form" v-model="formCadastroValido" validation>
      <v-row v-show="!loading">
        <v-col cols="12" lg="12">
          <h1>{{ !modoEdicao ? "Cadastro" : "Edição" }} de Modelos</h1>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model="modelo.descricao"
            autofocus
            label="Descrição do modelo"
            autocomplete="off"
            dense
            outlined
            :rules="campoObrigatorio"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-autocomplete
            v-model="modelo.tipoDeModelo"
            :items="tiposDeModelos"
            label="Tipo de Modelo"
            item-text="descricao"
            autocomplete="off"
            :rules="campoObrigatorio"
            dense
            return-object
            outlined
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-switch
            class="pt-2 mt-0"
            v-model="modelo.favorito"
            inset
            label="Salvar como favorito"
          >
          </v-switch>
        </v-col>
        <v-col cols="12" lg="12">
          <EditorNutri
            v-model="modelo.conteudo"
            :key="componentKey"
          ></EditorNutri>
        </v-col>
        <v-row class="pa-4">
          <v-col cols="12" class="text-right">
            <v-btn
              class="btn-primario"
              text
              :disabled="!formCadastroValido"
              @click="!modoEdicao ? cadastrarModelo() : atualizarModelo()"
              >{{ !modoEdicao ? "Cadastrar" : "Salvar" }}</v-btn
            >
            <v-btn class="btn-secundario ml-2" text @click="cancelar()"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-row>
      <janela-confirmacao
        v-model="janelaCancelarCadastro"
        :callbackPrimario="callbackPrimario"
        :mensagem="mensagemDeConfirmacao"
        :textoBotaoPrimario="'Sim, abandonar'"
        :textoBotaoSecundario="'Não'"
      ></janela-confirmacao>
    </v-form>
  </div>
</template>

<script>
// API
import apiModelos from "@/api/documento/modelo-api";
import apiTipoDeModelos from "@/api/documento/tipo-de-modelo-api";

// Models
import TipoDeModeloModel from "@/model/documento/tipo-de-modelo-model";
import ModeloModel from "@/model/documento/modelo-de-documento-model";

//Components
import EditorNutri from "@/components/comum/EditorTinymceNutri";

import mixpanel from "mixpanel-browser";
import mixinMensagem from "@/mixin/mixin-mensagem";
import JanelaConfirmacao from "@/components/JanelaConfirmacao";

export default {
  name: "CadastroDeModelos",
  components: {
    EditorNutri,
    JanelaConfirmacao,
  },
  mixins: [mixinMensagem],
  data() {
    return {
      componentKey: 0,
      formCadastroValido: false,
      campoObrigatorio: [(v) => !!v || "Informação obrigatória"],
      loading: true,
      modelos: [],
      modelo: new ModeloModel(),
      modoEdicao: false,
      tiposDeModelos: [],
      janelaCancelarCadastro: false,
      mensagemDeConfirmacao:
        "Há dados preenchidos, deseja abandonar o cadastro?",
    };
  },
  mounted() {
    this.obterTiposDeModelos();

    mixpanel.track("page_view", { nome_pagina: "modelos_documentos" });
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
      this.verificarModo();
    },
    verificarModo() {
      let id = this.$route.params.id;
      if (id) {
        this.modoEdicao = true;
        apiModelos
          .obterId(id)
          .then((resposta) => {
            this.modelo = new ModeloModel(resposta.data);
          })
          .catch(() => {
            this.dispararMensagem(
              "Não foi possível obter o modelo para edição.",
              "error"
            );
          });
      } else {
        this.modoEdicao = false;
        this.modelo = new ModeloModel();
      }
    },

    atualizarModelo() {
      mixpanel.track("click", {
        nome_pagina: "editar_modelos_documentos",
        nome_botao: "btn_atualizar_modelo",
      });
      apiModelos
        .atualizar(this.modelo)
        .then(() => {
          this.dispararMensagem("Modelo atualizado com sucesso", "success");
          this.$router
            .push({
              name: "controle-modelos",
            })
            .catch(() => {});
        })
        .catch(() => {
          this.dispararMensagem("Falha ao atualizar o modelo", "error");
        });
    },

    cancelar() {
      mixpanel.track("click", {
        nome_pagina: "cadastrar_modelos_documentos",
        nome_botao: "btn_cancelar_modelo",
      });
      if (
        this.modelo.descricao ||
        this.modelo.tipodeModelo ||
        this.modelo.conteudo
      ) {
        this.janelaCancelarCadastro = true;
      } else {
        history.back();
      }
    },

    cadastrarModelo() {
      mixpanel.track("click", {
        nome_pagina: "cadastrar_modelos_documentos",
        nome_botao: "btn_cadastrar_modelo",
      });
      apiModelos
        .cadastrar(this.modelo)
        .then((response) => {
          this.dispararMensagem(response.data, "success");
          this.$router
            .push({
              name: "controle-modelos",
            })
            .catch(() => {});
        })
        .catch((error) => {
          this.dispararMensagem(error.data, "error");
        });
      this.limparCampos();
    },

    callbackPrimario() {
      mixpanel.track("click", {
        nome_pagina: "cadastrar_modelos_documentos",
        nome_botao: "abandonar_cadastro",
      });
      this.$router
        .push({
          name: "controle-modelos",
        })
        .catch(() => {});
    },

    limparCampos() {
      this.modelo = undefined;
    },

    obterTiposDeModelos() {
      apiTipoDeModelos
        .obterTodos()
        .then((resposta) => {
          this.tiposDeModelos = resposta.data.map(
            (t) => new TipoDeModeloModel(t)
          );
          this.forceRerender();
          this.loading = false;
        })
        .catch(() => {
          /* Não fazer nada, é responsabilidade do sistema */
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  color: $cor_primaria;
}
</style> 