<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-show="loading">
      <v-col cols="12" lg="6">
        <v-skeleton-loader
          class="mx-4"
          type="heading"
          min-width="100px"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" lg="12">
        <v-divider class="mx-4"></v-divider>
        <v-skeleton-loader type="article" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        ></v-skeleton-loader>
        <v-skeleton-loader type="article" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="article" min-width="100px"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="formCadastroValido" validation>
      <v-row v-show="!loading">
        <v-col cols="12" lg="12">
          <h1>{{ !modoEdicao ? "Cadastro" : "Edição" }} de Documento</h1>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" lg="10">
          <v-text-field
            v-model="documento.descricao"
            :rules="campoObrigatorio"
            autofocus
            label="Descrição do modelo"
            autocomplete="off"
            dense
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-btn text class="btn-primario" @click="inserirModelo"
            >Inserir Modelo</v-btn
          >
        </v-col>
        <v-col cols="12" lg="12">
          <EditorNutri
            v-model="documento.conteudo"
            :key="componentKey"
          ></EditorNutri>
        </v-col>
        <v-row class="pa-4">
          <v-col cols="12" class="text-right">
            <v-btn
              class="btn-primario"
              text
              :disabled="!formCadastroValido || !this.documento.conteudo"
              @click="!modoEdicao ? cadastrarDocumento() : atualizarDocumento()"
              >{{ !modoEdicao ? "Cadastrar" : "Atualizar" }}</v-btn
            >
            <v-btn class="btn-secundario ml-2" text @click="cancelar()"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-row>

      <!-- Modal Modelos -->
      <v-dialog width="500px" v-model="modalInserirModelo" persistent>
        <v-card>
          <v-card-title>
            <h1 class="titulo-modal">Modelos</h1>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="12">
                <v-autocomplete
                  :items="modelos"
                  :autofocus="true"
                  item-text="descricao"
                  return-object
                  v-model="modelo"
                  label="Selecione um modelo"
                >
                </v-autocomplete>
              </v-col>
              <v-col v-show="modelo.conteudo" cols="12" lg="12">
                <span v-html="modelo.conteudo"></span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col class="text-right">
              <v-btn
                text
                class="btn-primario mr-2"
                @click="inserirModeloNoDocumento"
                :loading="loading"
              >
                Inserir
              </v-btn>
              <v-btn text class="btn-secundario" @click="fecharModal()">
                Fechar
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <janela-confirmacao
      v-model="janelaCancelarCadastro"
      :callbackPrimario="callbackPrimario"
      :mensagem="mensagemDeConfirmacao"
      :textoBotaoPrimario="'Sim, abandonar'"
      :textoBotaoSecundario="'Não'"
    ></janela-confirmacao>
  </div>
</template>

<script>
// Components
import EditorNutri from "@/components/comum/EditorTinymceNutri";
import JanelaConfirmacao from "@/components/JanelaConfirmacao";

// OUTROS
import moment from "moment";

// API
import apiDocumento from "@/api/documento/documento-api";
import apiModelos from "@/api/documento/modelo-api";

// MODEL
import DocumentoModel from "@/model/documento/documento-model";
import ModeloModel from "@/model/documento/documento-model";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "DocumentoTab",
  components: {
    EditorNutri,
    JanelaConfirmacao,
  },
  emits: {
    "disparar-mensagem": (evento) => {
      return !!(evento.mensagem && evento.tipo);
    },
    "excluir-documento": (evento) => {
      return !!(evento.callback && evento.mensagem);
    },
    "fechar-janela-confirmacao": undefined,
  },

  data() {
    return {
      conteudo: "",
      idDocumento: this.$route.params.idDocumento,
      idPaciente: this.$route.params.idPaciente,
      janelaCancelarCadastro: false,
      mensagemDeConfirmacao:
        "Há dados preenchidos, deseja abandonar o cadastro?",
      componentKey: 0,
      search: "",
      modoEdicao: false,
      documento: new DocumentoModel(),
      modelos: [],
      modelo: new ModeloModel(),
      formCadastroValido: false,
      modalInserirModelo: false,
      loading: true,
      campoObrigatorio: [(v) => !!v || "Informação obrigatória"],
      maximo50Caracter: (v) =>
        (!!v && v.length <= 50) || "Máximo de 50 caracteres",
    };
  },

  mounted() {
    this.verificarModo();
    this.obterModelos();
    this.forceRerender();
  },

  methods: {
    verificarModo() {
      let id = this.idDocumento;
      if (id) {
        this.modoEdicao = true;
        apiDocumento
          .obterId(id)
          .then((resposta) => {
            this.documento = new DocumentoModel(resposta.data);
          })
          .catch(() => {
            this.dispararMensagem(
              "Não foi possível obter o documento para edição.",
              "error"
            );
          });
      } else {
        this.modoEdicao = false;
        this.modelo = new ModeloModel();
      }
    },

    forceRerender() {
      this.componentKey += 1;
    },

    inserirModelo() {
      this.modalInserirModelo = true;
      mixpanel.track("click", {
        nome_pagina: "cadastro_documento",
        nome_botao: "abrir_modal_inserir_modelo",
        paciente_id: this.$route.params.idPaciente,
        documento_id: this.$route.params.idDocumento || "null",
      });
    },

    fecharModal() {
      mixpanel.track("click", {
        nome_pagina: "cadastro_documento",
        nome_botao: "fechar_modal_inserir_modelo",
        paciente_id: this.$route.params.idPaciente,
        documento_id: this.$route.params.idDocumento || "null",
      });
    },

    inserirModeloNoDocumento() {
      let conteudo = this.documento.conteudo;
      let modelo = this.modelo.conteudo;
      if (!conteudo) {
        this.documento.conteudo = modelo;
      } else {
        this.documento.conteudo = conteudo + modelo;
      }
      this.modalInserirModelo = false;
      this.limparModal();
      mixpanel.track("click", {
        nome_pagina: "cadastro_documento",
        nome_botao: "btn_inserir_modelo_no_documento",
        paciente_id: this.$route.params.idPaciente,
        documento_id: this.$route.params.idDocumento || "null",
      });
    },

    limparModal() {
      this.modelo = new ModeloModel();
    },

    cancelar() {
      mixpanel.track("click", {
        nome_pagina: "cadastrar_documentos",
        nome_botao: "btn_cancelar_documento",
        paciente_id: this.$route.params.idPaciente,
        documento_id: this.$route.params.idDocumento || "null",
      });
      if (this.documento.descricao || this.documento.conteudo) {
        this.janelaCancelarCadastro = true;
      } else {
        this.voltar();
      }
    },

    voltar() {
      this.$router
        .push({
          name: "prontuario-paciente",
          params: {
            id: this.idPaciente,
          },
        })
        .catch(() => {});
    },

    callbackPrimario() {
      this.voltar();
    },

    obterModelos() {
      apiModelos
        .obterTodos()
        .then((resposta) => {
          this.modelos = resposta.data.map((m) => new ModeloModel(m));
          this.loading = false;
        })
        .catch((erro) => {
          this.dispararMensagem(
            (erro.response && erro.response.data.mensagem) || erro,
            "error"
          );
          this.loading = false;
        });
    },

    cadastrarDocumento() {
      this.documento.idPaciente = this.idPaciente;
      apiDocumento
        .cadastrar(this.documento)
        .then(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Cadastrado com sucesso!",
            tipo: "success",
          });
          this.$router.push({
            name: "prontuario-paciente",
            params: { id: this.idPaciente },
          });
        })
        .catch((error) => {
          this.$emit("disparar-mensagem", {
            mensagem: error,
            tipo: "error",
          });
        });
    },

    atualizarDocumento() {
      this.documento.idPaciente = this.$route.params.idPaciente;

      apiDocumento
        .atualizar(this.documento)
        .then(() => {
          this.$router.push({
            name: "prontuario-paciente",
            params: { id: this.idPaciente },
          });
          this.$emit("disparar-mensagem", {
            mensagem: "Atualizado com sucesso!",
            tipo: "success",
          });
        })
        .catch((error) => {
          this.$emit("disparar-mensagem", {
            mensagem: error,
            tipo: "error",
          });
        });
    },
  },

  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.texto-modal {
  color: black;
}
.titulo-modal {
  font-weight: 400;
  padding-bottom: 5px;
}
.subtitulo-modal {
  font-weight: 500;
}
.timeline-card-topo {
  background-color: $cor_primaria;
  color: $branco;
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  display: inline-block;
  padding: 10px;
}

.timeline-card-texto {
  padding: 10px;
}

.timeline-topo-chave {
  font-weight: 500;
}

.linha {
  min-height: 300px;
}

.historico {
  position: absolute;
  padding-bottom: 50px;
  right: 0;
  height: 100%;
  overflow: auto;
}

@media only screen and (max-width: 1263px) {
  .historico {
    overflow: auto;
    position: inherit;
  }
}
</style>