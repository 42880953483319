<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="0"
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      v-model="drawer"
      app
      :expand-on-hover="!isMobile"
      :mini-variant="!isMobile"
      >
      <MenuStatic></MenuStatic>
    </v-navigation-drawer>
    <v-app-bar color="white" elevation="0" app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <img
        id="logotipo-barra-topo"
        class="pa-2"
        src="@/assets/svg/logotipo.svg"
        height="50px"
      />
      <v-toolbar-title> </v-toolbar-title>
      <v-spacer></v-spacer>
      <CadastroConsultaModal> </CadastroConsultaModal>
      <v-menu offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                ><settings-icon></settings-icon>
              </v-btn>
            </template>
            <span>Opções</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="alterarSenha()">
              <key-icon size="1.4x" class="mr-2"></key-icon>
              Alterar Senha
            </v-list-item>
            <v-list-item @click="sair()">
              <log-out-icon size="1.2x" class="mr-2"></log-out-icon>
              Sair
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-dialog width="300px" v-model="janelaAlterarSenha">
      <v-card>
        <v-card-title>
          <h3>Alterar Senha</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-text-field
            label="Nova Senha"
            required
            :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            autofocus
            v-model="usuario.novaSenha"
          ></v-text-field>
          <v-text-field
            label="Confirme sua senha"
            required
            :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            v-model="usuario.novaSenhaConfirma"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="redefinirSenha()" class="btn-primario" text
            >Salvar
          </v-btn>
          <v-btn @click="fechar()" class="btn-terciario" text>Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Api
import apiUsuario from "@/api/usuario/usuario-api";

// Models
import UsuarioModel from "@/model/usuario-model";

// Components
import MenuStatic from "@/components/menu/MenuStatic.vue";
import CadastroConsultaModal from "@/components/modais/consulta/CadastroConsulta";

// Utils
import utilsStorage from "@/utils/storage";

// Mixin
import MixinMensagem from "@/mixin/mixin-mensagem";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "Menu",
  mixins: [MixinMensagem],
  components: {
    MenuStatic,
    CadastroConsultaModal,
  },
  data() {
    return {
      drawer: true,
      rotas: [],
      janelaAlterarSenha: false,
      value: "password",
      usuario: new UsuarioModel(),
    };
  },
  computed: {
    isMobile(){
      return window.innerWidth <= '960';
    }
  },
  mounted() {
    this.iniciarPendo();
  },

  methods: {
    alterarSenha() {
      this.janelaAlterarSenha = true;
      mixpanel.track("click", {
        nome_botao: "btn_alterar_senha",
      });
    },
    sair() {
      mixpanel.track("click", {
        nome_botao: "btn_sair",
      });
      this.logout();
    },

    logout() {
      apiUsuario
        .deslogar()
        .then(() => {
          utilsStorage.removerAutenticacao();
          this.$router.push({ name: "login" });
        })
        .catch((error) =>
          this.dispararMensagem(error.response.data.mensagem, "error")
        );
    },
    iniciarPendo() {
      if (!window.pendo) return;

      window.pendo.initialize({
        visitor: {
          id: this.usuario.id,
          email: this.usuario.email,
          full_name: this.usuario.nome,
          role: this.usuario.idPerfil,
        },
        account: {
          guid: this.usuario.guidAssinante,
        },
      });
    },
    redefinirSenha() {
      if (this.usuario.novaSenha === this.usuario.novaSenhaConfirma) {
        apiUsuario
          .redefinirSenha(this.usuario.novaSenha)
          .then(() => {
            this.dispararMensagem("Senha alterada com sucesso", "success");
            this.janelaAlterarSenha = false;
            this.limparCampos();
          })
          .catch(() => {
            this.dispararMensagem(
              "Falha na redefinição de senha, verifique os campos",
              "error"
            );
          });
      } else {
        this.dispararMensagem("Informe senhas iguais", "error");
      }
    },

    fechar() {
      this.janelaAlterarSenha = false;
    },

    limparCampos() {
      this.usuario = new UsuarioModel();
    },
  },
};
</script>

<style lang="scss" scoped></style>
