import api from '../api'

function obterPorPaciente(idPaciente){
  return  new Promise((resolve, reject) =>{
    return api.get(`/pacientes/${idPaciente}/prescricao/dietetica`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function obterPorId(id) {
  return new Promise((resolve, reject) => {
    return api.get(`/prescricao/dietetica/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

function cadastrar(prescricao){
  prescricao = prescricao || {};
  return  new Promise((resolve, reject) =>{
    return api.post(`/prescricao/dietetica`, prescricao)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function deletar(id){
  return  new Promise((resolve, reject) =>{
    return api.delete(`/prescricao/dietetica/${id}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function atualizar(prescricao){
  prescricao = prescricao || {};
  return  new Promise((resolve, reject) =>{
    return api.put(`/prescricao/dietetica/${prescricao.id}`, prescricao)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

export default {
  obterPorPaciente,
  obterPorId,
  cadastrar,
  deletar,
  atualizar,
}