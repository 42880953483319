import api from '../api'

function cadastrar(calculo) {
	calculo = calculo || {};
	return new Promise((resolve, reject) => {
		return api.post(`/calculos/energeticos`, calculo)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}


function obterCalculoPorPaciente(idPaciente) {
	return new Promise((resolve, reject) => {
		return api.get(`/pacientes/${idPaciente}/calculos/energeticos`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function obterCalculoPorId(id) {
	return new Promise((resolve, reject) => {
		return api.get(`/calculos/energeticos/${id}`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function atualizar(calculo) {
	calculo = calculo || {};
	return new Promise((resolve, reject) => {
		return api.put(`/calculos/energeticos/${calculo.id}`, calculo)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function deletar(idCalculo) {
	return new Promise((resolve, reject) => {
		return api.delete(`/calculos/energeticos/${idCalculo}`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

export default {
	cadastrar,
	obterCalculoPorPaciente,
	obterCalculoPorId,
	atualizar,
	deletar
}